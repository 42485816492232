<template>
    <main class="main nd_auth">
        <div class="section section--breadcrumbs">
          <div class="container-l">
            <div class="inner">
              <ul class="list list--flex breadcrumbs">
                <li class="list-item"><a class="list-link" href="#">
                    <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                      <use xlink:href="@/assets/img/icons.svg#home"></use>
                    </svg></a></li>
                <li class="list-item"><a class="list-link" href="#">Вход</a></li>
                <li class="list-item"><span class="list-current">Активация</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section section--no-pt">
          <div class="container-l">
            <div class="row row--login">
              <div class="col">
                <div class="auth_left_block">
                  <img 
                      v-if="step != 1"
                      @click="step = 1"
                      class="auth_icon_back"
                      src="~@/img/icon_back.png" 
                      alt=""
                  >
                  <div class="heading">
                    <div class="heading-section">
                      <p v-if="step == 1">Изменение пароля</p>
                      <p v-if="step == 2">Письмо отправлено</p>
                    </div>
                  </div>
                  <div v-if="step == 2" class="subheading">
                    <p class="second_step_info">Новый пароль успешно сохранен. Теперь вы можете войти в личный кабинет с новым паролем</p>
                  </div>
                  <form class="form" action="#" method="post" onsubmit="return false">
                    <div class="form-fieldset">
                      <div v-if="step == 1" class="form-group">
                        <div class="form-item">
                          <div class="field">
                            <div class="field-input p-rel">
                              <input 
                                :type="isShowPassword ? 'text' : 'password'" 
                                placeholder="Новый пароль" 
                                v-model="password"
                                class="nd_auth_input"
                                :class="errorClass"
                                @keyup="setErrorClass(false)"
                              >
                              <img
                                v-if="isShowPassword" 
                                src="~@/img/password_input_icon1.png" 
                                alt=""
                                class="icon-show-password"
                                @click="isShowPassword = !isShowPassword"
                              >
                              <img 
                                v-else
                                src="~@/img/password_input_icon2.png"
                                alt=""
                                class="icon-show-password"
                                @click="isShowPassword = !isShowPassword"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="step == 1" class="form-group">
                        <div class="form-item">
                          <div class="field">
                            <div class="field-input p-rel">
                              <input 
                                :type="isShowPassword ? 'text' : 'password'" 
                                placeholder="Повторите пароль" 
                                v-model="confirm"
                                class="nd_auth_input"
                                :class="errorClass"
                                @keyup="setErrorClass(false)"
                              >
                              <img
                                v-if="isShowPassword" 
                                src="~@/img/password_input_icon1.png" 
                                alt=""
                                class="icon-show-password"
                                @click="isShowPassword = !isShowPassword"
                              >
                              <img 
                                v-else
                                src="~@/img/password_input_icon2.png"
                                alt=""
                                class="icon-show-password"
                                @click="isShowPassword = !isShowPassword"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <p style="color: red; margin-bottom: 0;">{{ errorMessage }}</p>
                      <div class="form-group" style="margin-top: 20px">
                        <div class="form-item">
                          <button v-if="step == 1" class="button button--primary nd-br nd_button" @click="activate">Продолжить</button>
                          <button v-if="step == 2" class="button_nd_success activate_button mb-20">
                            <img src="~@/img/nd_icon_check.png" alt="" class="nd_icon_check">
                            Пароль сохранен
                          </button>
                          <button v-if="step == 2" class="button button--primary nd-br nd_button" @click="entry">Войти в личный кабинет</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col">
                <div class="auth_right_block">
                  <img src="~@/img/auth_img.png" alt="">
                  <p class="title" @click="push('registration')">Еще не с нами?</p>
                  <p class="subtitle">
                      После регистрации на сайте вам будет
                      доступно отслеживание состояния заказов,
                      личный кабинет и другие новые
                      возможности
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    data() {
        return {
            password: null,
            confirm: null,
            isShowPassword: false,

            errorClass: '',
            errorMessage: '',
            step: 1,

            formLogin: {
              login: null,
              password: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            message: 'auth/message',
            order: 'basket/order',
        }),
        key() {
            return this.$route.params.key
        },
        order_id () {
          let id = null

          if (localStorage.mayakOrderId) {
              id = JSON.parse(localStorage.getItem("mayakOrderId"))
          }else if (this.$route.query.order_id) {
              id = this.$route.query.order_id
              localStorage.setItem('mayakOrderId', JSON.stringify(id))
          }

          return Number(id)
        },
    },
    mounted() {
        // this.checkOrder()
    },
    methods: {
        ...Vuex.mapActions({
            activateUser: 'auth/activate',
            auth: 'auth/auth',
            getOrder: 'basket/get',
        }),
        ...Vuex.mapMutations({
            setMessage: 'auth/setMessage',
            setShowErrorActivateUser: 'app/setShowErrorActivateUser',
        }),
        setErrorClass(val, message) {
          if (val == true) {
            this.errorClass = 'error_red_border_input'
            if (message) {
              this.errorMessage = message
            } else {
              this.errorMessage = 'Пароли не совпадают'
            }
          }else{
            this.errorClass = ''
            this.errorMessage = ''
          }
        },
        checkOrder() {
            if (this.order_id && this.order_id != 0) {
                this.getOrder(this.order_id)
            }
        },
        checkConfirm() {
            if (this.password == this.confirm) {
              return true
            } else {
              this.setErrorClass(true)
              return false
            }
        },
        checkEmptys() {
          if (this.password && this.confirm) {
            return true
          } else {
            this.setErrorClass(true, 'Заполните поля')
            return false
          }
        },
        entry() {
          this.auth(this.formLogin)
            .then(() => {
                if (this.order.id) {
                  setTimeout(function(){
                    this.setMessage(null)
                    this.push('basket/checkout')
                  }.bind(this), 1500)
                }else{
                  setTimeout(function(){
                    this.setMessage(null)
                    this.push('')
                  }.bind(this), 1500)
                } 
            })
        },
        activate() {
            if (!this.checkConfirm() || !this.checkEmptys()) {
              return
            }
            this.activateUser({
                password: this.password,
                confirm: this.confirm,
                key: this.key
            })
                .then((response) => {
                  this.formLogin.login = response.data.email
                  this.formLogin.password = this.password

                  this.step = 2 
                })
                .catch((error) => {
                  if (error.response.status != 500) {
                    this.setShowErrorActivateUser(true)
                  }
                })
        },
    },
}
</script>