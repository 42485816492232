<template>
    <main class="admin-index index__admin admin-contacts" style="display: block;">
        <div class="admin-index_header">
            <h3>Контакты</h3>
        </div>
        <div class="index__admin admin-contacts-content">
            <div class="index__admin addresses">
                <span>Баннеры</span>
                <button v-if="!isShowCreateFormBanner" @click="isShowCreateFormBanner = true">
                    Добавить баннер
                </button>

                <div v-if="isShowCreateFormBanner" class="forms">
                    <div class="singleAddress" style="margin-top: 0;">
                        <div
                            class="index__admin" 
                        >
                            <div
                                class="region index__admin" 
                            >
                                <select 
                                    v-model="createFormBanner.root_id" 
                                >
                                    <option value="">Выберите регион</option>
                                    <option 
                                        v-for="(category, idx) in topLevelCategory"
                                        :key="idx"
                                        :value="category.id_1c"
                                    >
                                        {{ category.name }}
                                    </option>
                                </select>
                            </div>
                            <input 
                                v-model="createFormBanner.link_image"
                                type="text" 
                                placeholder="Ссылка на изображение"
                            >
                            <img v-if="createFormBanner.link_image != null" :src="createFormBanner.link_image" alt="" class="coating_main_img">
                            <div class="row">
                                <label class="fl__img">
                                    <input 
                                        :ref="'contactsUploadBanner'" 
                                        type="file" 
                                        @change="uploadImg"
                                    >
                                </label>
                                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                            </div>
                        </div>
                        <div class="buttons">
                            <button 
                                class="delete_button"
                                @click="closeCreateFormBanner"
                            >
                                Отменить
                            </button>
                            <button 
                                class="delete_button"
                                @click="createBanner"
                            >
                                Добавить
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="!isShowCreateFormBanner">
                    <single-banner 
                        v-for="singleBanner, idx in banners"
                        :key="idx"
                        :element="singleBanner"
                        :index="idx"
                    />
                </div>

                <span>Адреса</span>
                <button v-if="!isShowCreateForm" @click="isShowCreateForm = true">
                    Добавить подразделение
                </button>
                
                <div class="forms">
                    <div
                        v-if="isShowCreateForm" 
                        class="singleAddress"
                    >
                        <div class="checkboxes">
                            <div>
                                <label class="checkbox-plus">
                                <input 
                                    v-model="typesAddress.isMainOffice"
                                    type="checkbox" 
                                    name="privacy"
                                >
                                <b />
                                <span style="margin-top: 0;">Головной офис</span>
                                </label>
                            </div>
                            <div>
                                <label class="checkbox-plus">
                                <input
                                    v-model="typesAddress.isOffice"
                                    type="checkbox" 
                                    name="privacy"
                                >
                                <b />
                                <span style="margin-top: 0;">Офис продаж</span>
                                </label>
                            </div>
                            <div>
                                <label class="checkbox-plus">
                                <input 
                                    v-model="typesAddress.isProduction"
                                    type="checkbox" 
                                    name="privacy"
                                >
                                <b />
                                <span>Производство</span>
                                </label>
                            </div>
                            <div>
                                <label class="checkbox-plus">
                                <input 
                                    v-model="typesAddress.isWarehouse"
                                    type="checkbox" 
                                    name="privacy"
                                >
                                <b />
                                <span>Склад</span>
                                </label>
                            </div>
                        </div>
                        <div
                            class="region index__admin" 
                        >
                            <select 
                                v-model="createForm.region" 
                            >
                                <option value="">Выберите регион</option>
                                <option 
                                    v-for="(category, idx) in topLevelCategory"
                                    :key="idx"
                                    :value="category.id_1c"
                                >
                                    {{ category.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="index__admin" 
                        >
                            <input 
                                v-model="createForm.sort"
                                type="text" 
                                placeholder="Сортировка"
                            >
                            <input 
                                v-model="createForm.link"
                                type="text" 
                                placeholder="Ссылка на карту"
                            >
                            <input 
                                v-model="createForm.city"
                                type="text"
                                placeholder="Город"
                            >
                            <input 
                                v-model="createForm.street"
                                type="text"
                                placeholder="Улица"
                            >
                        </div>
                        <div 
                            class="index__admin"
                        >
                            <input 
                                v-model="createForm.lat"
                                type="text" 
                                placeholder="Координаты: Широта"
                                :class="requireElementsValueError.lat ? 'error_input' : ''"
                                @keyup="validation"
                            >
                            <p class="error_label" v-if="requireElementsValueError.lat && !requireElementsValueError.lng">Пожалуйста заполните поле "широта"</p>
                            <input 
                                v-model="createForm.lng"
                                type="text" 
                                placeholder="Координаты: Долгота"
                                :class="requireElementsValueError.lng ? 'error_input' : ''"
                                @keyup="validation"
                            >
                            <p class="error_label" v-if="requireElementsValueError.lng && !requireElementsValueError.lat">Пожалуйста заполните поле "широта"</p>
                            <p class="error_label" v-if="requireElementsValueError.lat && requireElementsValueError.lng">Пожалуйста заполните координаты</p>
                            <input 
                                v-model="createForm.working_hours"
                                type="text"
                                placeholder="Режим работы"
                            >
                            <input 
                                v-model="createForm.email"
                                type="text"
                                placeholder="Почта"
                            >
                            <input 
                                v-model="createForm.phone"
                                type="text"
                                placeholder="Телефон"
                            >
                        </div>
                        <div class="buttons">
                            <button 
                                class="delete_button"
                                @click="closeCreateForm"
                            >
                                Отменить
                            </button>
                            <button 
                                class="delete_button"
                                @click="create"
                            >
                                Добавить
                            </button>
                        </div>
                    </div>
                    <div v-if="!isShowCreateForm" class="admin-contacts-list-block">
                        <single-address
                            v-for="(address, idx) in ui_contacts"
                            :key="address.id"
                            :index="idx"
                            :element="address"
                        />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import SingleAddress from './singleAddress.vue'
import SingleBanner from './singleBanner.vue'

export default {
    components: {
        SingleAddress,
        SingleBanner,
    },
    data() {
        return {
            map: {},
            markers: [],
            amoutChangeCotacts: 0,

            isShowCreateFormBanner: false,
            createFormBanner: {
                link_image: null,
                root_id: "",
            },

            isShowCreateForm: false,
            typesAddress: {
                isMainOffice: false,
                isOffice: false,
                isProduction: false,
                isWarehouse: false,
            },
            createForm: {
                sort: null,
                is_type: [],
                region: "",
                link: null,
                city: null,
                street: null,
                lat: null,
                lng: null,
                working_hours: null,
                email: null,
                phone: null,
            },

            center: [ 52.56543518842296, 70.05213952539054 ],
            zoom: 4,

            settings: {
                apiKey: '72b24e1d-06ad-424f-af9b-44d28d93d4d6',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            },
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/img/map-marker.svg'),
                imageSize: [43, 43],
                imageOffset: [-20, -20],
            },
            requireElementsValueError: {
                lat: false,
                lng: false,
            }
        }
    },
    computed: {
        ...Vuex.mapGetters({
            ui_contacts: 'ui_contacts/contacts',
            indexEditedContactAddress: 'app/indexEditedContactAddress',
            topLevelCategory: 'dictionary/topLevelCategory',
            banners: 'ui_contacts_banner/banners',
            fileUrl: 'app/fileUrl',
        }),
    },
    mounted() {
        this.getContactsBanner({})
        this.getContacts()
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            getContacts: 'ui_contacts/getContacts',
            createContact: 'ui_contacts/createContact',
            getContactsBanner: 'ui_contacts_banner/getContactsBanner',
            upload: 'app/upload',
            createContactBanner: 'ui_contacts_banner/createContactBanner',
        }),
        ...Vuex.mapMutations({
            pushContactAddress: 'app/pushContactAddress',
            updateCoordinateAddress: 'app/updateCoordinateAddress',
        }),
        validation() {
            var value = true
            if (!this.createForm.lat) {
                this.requireElementsValueError.lat = true
                value = false
            }else{
                this.requireElementsValueError.lat = false
            }
            if (!this.createForm.lng) {
                this.requireElementsValueError.lng = true
                value = false
            }else{
                this.requireElementsValueError.lng = false
            }
            return value
        },
        closeCreateFormBanner() {
            this.isShowCreateFormBanner = false
            this.createFormBanner.link_image = null
            this.createFormBanner.root_id = ""
        },
        createBanner() {
            this.createContactBanner(this.createFormBanner)
                .then(() => {
                    this.closeCreateFormBanner()
                })
        },
        updateData() {
            this.updateJson()
        },
        closeCreateForm() {
            this.typesAddress.isMainOffice = false
            this.typesAddress.isOffice = false
            this.typesAddress.isProduction = false
            this.typesAddress.isWarehouse = false

            this.createForm = {
                sort: null,
                is_type: [],
                region: null,
                link: null,
                city: null,
                street: null,
                lat: null,
                lng: null,
                working_hours: null,
                email: null,
                phone: null,
            }

            this.isShowCreateForm = false
        },
        create() {
            if (!this.validation()) {
                return
            }
            var copyForm = this.createForm
            if (this.typesAddress.isMainOffice) {
                copyForm.is_type.push('isMainOffice')
            }
            if (this.typesAddress.isOffice) {
                copyForm.is_type.push('isOffice')
            }
            if (this.typesAddress.isProduction) {
                copyForm.is_type.push('isProduction')
            }
            if (this.typesAddress.isWarehouse) {
                copyForm.is_type.push('isWarehouse')
            }
            if (!this.createForm.sort) {
                this.copyForm.sort = 0
            }
            this.createContact(copyForm)
                .then(() => {
                    this.closeCreateForm()
                })
        },
        addAddress() {
            this.pushContactAddress({
                isOffice: false,
                isProduction: false,
                isWarehouse: false,
                region: null,
                city: null,
                street: null,
                working_hours: null,
                lat: 57.974887,
                lng: 56.263805,
                visible: true,
                region_id_1c: null,
                email: null,
                phone: null,
            })
        },
        setPoint(event) {
            let coords = []
            
            if (event.get('coords')) {
                coords = event.get('coords')
            }
            if (coords.length != 2) {
                return
            }
            this.updateCoordinateAddress({
                idx: this.indexEditedContactAddress,
                lat: coords[0],
                lng: coords[1],
            })
        },
        uploadImg() {
            let image = this.$refs['contactsUploadBanner'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.createFormBanner.link_image = this.fileUrl + response.data[0].link
                })
        },
    },
}
</script>
<style lang="scss">
    .admin-contacts-list-block{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
</style>