import Http from "../../utils/http"
import Config from "../../config"
import axios from "axios"

const state = {
    message: null,
    form: {
        // excludedIds: [],
        // ids: [],
        // limit: 10,
        // name: "",
        // page: 1,
        // query: "",
        // type: ""
    },
    createForm: {
        "name": null,
        "type": null,
    },
    vacancies: [],
    isShowModalCreate: false,
    currentVacancy: {},
    vacanciesMainPage: []
}

const getters = {
    message: state => state.message,
    form: state => state.form,
    createForm: state => state.createForm,
    vacancies: state => state.vacancies,
    isShowModalCreate: state => state.isShowModalCreate,
    currentVacancy: state => state.currentVacancy,
    vacanciesMainPage: state => state.vacanciesMainPage,
}

const actions = {
    search: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_work/search", getters.form)
                .then(response => {
                    commit("setVacancyMainPage", response.data)
                    resolve(response)
                })
        })
    },
    getVacancies: ({commit}) => {
        return new Promise((resolve) => {
            axios.get("https://api.hh.ru/vacancies?employer_id=41770&per_page=100")
                .then(response => {
                    commit("setVacancy", response.data)
                    resolve(response)
                })
        })
    },
    getCurrentVacancy: ({commit}, id) => {
        return new Promise((resolve) => {
            axios.get("https://api.hh.ru/vacancies/" + id)
                .then(response => {
                    commit("setCurrentVacancy", response.data)
                    resolve(response)
                })
        })
    },
    createVacancy: ({commit}, payload) => {
        var object = Object.assign({}, payload)
        object.main_block = JSON.stringify(object.main_block)
        object.progress_block = JSON.stringify(object.progress_block)
        object.general_block = JSON.stringify(object.general_block)
        object.banner_block = JSON.stringify(object.banner_block)

        var str_advantage_list = []
        object.advantages_list.map((element) => {
            str_advantage_list.push(JSON.stringify(element))
        })
        object.advantages_list = str_advantage_list

        var str_question_list = []
        object.questions_list.map((element) => {
            str_question_list.push(JSON.stringify(element))
        })
        object.questions_list = str_question_list

        var str_interview_list = []
        object.interview_list.map((element) => {
            str_interview_list.push(JSON.stringify(element))
        })
        object.interview_list = str_interview_list

        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/ui_work/admin", object)
                .then(response => {
                    commit("addVacancyMainPage", payload)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                    if (err.message) {
                        commit("setMessage", err.message)
                    }
                })
        })
    },
    deleteVacancy: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_work/admin/" + id)
                .then(response => {
                    commit("spliceVacancyMainPage", id)
                    resolve(response)
                })
        })
    },
    updateVacancy: ({commit}, payload) => {
        var object = Object.assign({}, payload)
        object.main_block = JSON.stringify(object.main_block)
        object.progress_block = JSON.stringify(object.progress_block)
        object.general_block = JSON.stringify(object.general_block)
        object.banner_block = JSON.stringify(object.banner_block)

        var str_advantage_list = []
        object.advantages_list.map((element) => {
            str_advantage_list.push(JSON.stringify(element))
        })
        object.advantages_list = str_advantage_list

        var str_question_list = []
        object.questions_list.map((element) => {
            str_question_list.push(JSON.stringify(element))
        })
        object.questions_list = str_question_list

        var str_interview_list = []
        object.interview_list.map((element) => {
            str_interview_list.push(JSON.stringify(element))
        })
        object.interview_list = str_interview_list

        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_work/admin/" + payload.id, object)
                .then(response => {
                    commit("patchVacancyMainPageObject", {id: payload.id, object: payload})
                    resolve(response)
                })
        })
    },
    sendResume: ({commit}, payload) => {
        var formData = new FormData()
        formData.append("type", "summary")
        console.log(payload)
        if (payload.name) {
            formData.append("name", payload.name)
        }
        if (payload.email) {
            formData.append("email", payload.email)
        }
        if (payload.phone) {
            formData.append("telephone", payload.phone)
        }
        if (payload.city) {
            formData.append("city", payload.city)
        }
        if (payload.link_summary) {
            formData.append("link_summary", payload.link_summary)
        }
        if (payload.file) {
            formData.append("file", payload.file)
        }
        if (payload.comment) {
            formData.append("comment", payload.comment)
        }
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/summary", formData, {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    commit("setMessage", 'success send')
                    resolve(response)
                })
        })
    },
    sendQuestion: ({commit}, payload) => {
        var formData = new FormData()
        formData.append("question", payload.question)
        formData.append("type", "question")
        if (payload.email) {
            formData.append("email", payload.email)
        }
        if (payload.phone) {
            formData.append("telephone", payload.phone)
        }
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/summary/question", formData, {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    commit("setMessage", 'success send')
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setCurrentVacancy: (state, val) => {
        state.currentVacancy = val
    },
    setVacancy: (state, val) => {
        state.vacancies = val.items
    },
    setVacancyMainPage: (state, val) => {
        val.map((element) => {
            element.main_block = JSON.parse(element.main_block)
            element.progress_block = JSON.parse(element.progress_block)
            element.general_block = JSON.parse(element.general_block)
            element.banner_block = JSON.parse(element.banner_block)

            var parsedAdvantageList = []
            element.advantages_list.map((element) => {
                parsedAdvantageList.push(JSON.parse(element))
            })
            element.advantages_list = parsedAdvantageList

            var parsedQuestionList = []
            element.questions_list.map((element) => {
                parsedQuestionList.push(JSON.parse(element))
            })
            element.questions_list = parsedQuestionList

            var parsedInterviewList = []
            element.interview_list.map((element) => {
                parsedInterviewList.push(JSON.parse(element))
            })
            element.interview_list = parsedInterviewList
        })
        state.vacanciesMainPage = val
    },
    spliceVacancyMainPage: (state, id) => {
        state.vacanciesMainPage.map((element, i) => {
            if (id == element.id) {
                state.vacanciesMainPage.splice(i, 1)
            }
        })
    },
    patchVacancyMainPageObject: (state, payload) => {
        state.vacanciesMainPage.map((element) => {
            if (payload.id == element.id) {
                element.main_block = payload.object.main_block
                element.progress_block = payload.object.progress_block
                element.video_link = payload.object.video_link
                element.advantages_list = payload.object.advantages_list
                element.general_block = payload.object.general_block
                element.banner_block = payload.object.banner_block
                element.questions_list = payload.object.questions_list
                element.interview_list = payload.object.interview_list
                element.root_ids = payload.object.root_ids
            }
        })
    },
    addVacancyMainPage: (state, val) => {
        state.vacanciesMainPage.push(val)
    },
    setShowModalCreate: (state, val) => {
        state.isShowModalCreate = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
