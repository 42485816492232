<template>
    <section class="about_company-info_page-sp-evosteel_description">
        <div class="container-l"> 
            <!-- <p class="main_header">Сэндвич-панели производства МаякМеталл</p>  -->
            <div class="description_block">
                <div class="left_part">
                    <img class="description_img" src="@/img/info-sp/description1.png" alt="">
                </div>
                <div class="right_part">
                    <p class="description_text">
                        В РФ наиболее часто встречаются cтали, выпущенные по Американскому стандарту, 
                        они имеют маркировку AISI.
                    </p>
                    <p class="description_subtext">
                        Обычная сталь не способна обеспечить надежную эксплуатацию панелей в случае воздействия 
                        агрессивных сред. EN ISO 12944:2017 (ГОСТ 34667.2) «Краски и лаки - защита от коррозии 
                        стальных конструкций системами защитных красок». Применяются в пищевой, химической, текстильной, 
                        нефтяной, бумажной, фармацевтической промышленности. В зданиях на береговой линии при 
                        воздействиях морской атмосферы
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {

}
</script>