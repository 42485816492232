import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    banners: [],
}

const getters = {
    message: state => state.message,
    banners: state => state.banners,
}

const actions = {
    getContactsBanner: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_ad_banner/search", payload)
                .then(response => {
                    commit("setContactsBanner", response.data)
                    resolve(response)
                })
        })
    },
    createContactBanner: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_ad_banner/admin", payload)
                .then(response => {
                    commit("pushContactBanner", response.data)
                    resolve(response)
                })
        })
    },
    deleteContactBanner: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_ad_banner/admin/" + id)
                .then(response => {
                    commit("spliceContactBanner", id)
                    resolve(response)
                })
        })
    },
    updateContactBanner: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_ad_banner/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeContactBanner", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setContactsBanner: (state, val) => {
        state.banners = val
    },
    spliceContactBanner: (state, id) => {
        state.banners.map((element, idx) => {
            if (id == element.id) {
                state.banners.splice(idx, 1)
            }
        })
    },
    changeContactBanner: (state, val) => {
        state.banners.map((element) => {
            if (element.id == val.id) {
                element.link_image = val.link_image
                element.root_id = val.root_id
            }
        })
    },
    pushContactBanner: (state, val) => {
        state.banners.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
