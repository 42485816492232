<template>
    <div class="block-drainage setting-cards">
        <div class="table table_wscroll">
            <div class="category" :class="{'-active' : calc_drain_body.root_1c_id == category.id_1c}" v-for="category in topCategory" :key="category.id" @click="getCalcDrain(category.id_1c)">
                <p>{{ category.name }}</p>
            </div>
        </div>
        <label v-if="calc_drain_body.root_1c_id">Водосточные системы</label>
        <div class="block_array" v-if="calc_drain_body.systems && calc_drain_body.systems.length != 0">
            <p v-for="system, idx in calc_drain_body.systems" :key="idx">
                <span>{{ system }} / {{ getOneDrainName(system) }}</span>
                <span style="margin-left: 5px; color: red; cursor: pointer" @click="deleteSystem(system)">X</span>
            </p>
        </div>

        <div 
            v-if="calc_drain_body.root_1c_id"
            class="search-field"
        >
            <input 
                class="search-input"
                type="text" 
                v-model="query"
                @keyup="getElements()"
            >
            <button 
                class="search-submit" 
                @click="getElements()"
            >
                <span class="search-submit-label">найти</span>
            </button>
        </div>
        <div 
            v-if="query.length > 3"
            class="block-elements mb-10"
        >
            <div class="search-results mt-0"  data-search-results>
                <div 
                    class="search-result"
                    v-for="product in elements"
                    :key="product.id"
                >
                    <div class="search-result-title">
                        <a class="search-result-link">{{ product.name }}</a>
                    </div>
                    <div class="search-result-button">
                        <button 
                            class="button button--primary button--cart"
                            @click="addSystem(product.id_1c)"
                        >
                            Выбрать
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div 
            v-if="calc_drain_body.root_1c_id"
            class="additional-block mb-10 mt-5"
        >
            <label>Доборные элементы:</label>
        
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <th>
                        Название
                    </th>
                    <th>
                        Поиск
                    </th>
                </tr>
                <one-tr-drain :name="'sealant'" />
                <one-tr-drain :name="'pistol'" />
                <one-tr-drain :name="'corrector'" />
            </table>
        </div>
        <calculator-step :step="'step1'" :calculatorType="'calculatorDrainage'" />
        <calculator-step :step="'step1A'" :calculatorType="'calculatorDrainage'" />
        <calculator-step :step="'step2'" :calculatorType="'calculatorDrainage'" />
        <calculator-step :step="'step3'" :calculatorType="'calculatorDrainage'" />
        <calculator-step :step="'step4'" :calculatorType="'calculatorDrainage'" />
        <calculator-step :step="'step5'" :calculatorType="'calculatorDrainage'" />
        <div class="block-step">
            <h4>Общее описание</h4>
            <label>Заголовок</label>
            <input 
                type="text" 
                v-model="calculatorGeneralDescription.header" 
                @keyup="updateGeneralDescription"
            >
            <label>Текст</label>
            <vue-editor 
                v-model="calculatorGeneralDescription.text" 
                @text-change="updateGeneralDescription"
            />
        </div>
    </div>
</template>
<script>
import calculatorStep from './calculator-step.vue'
import Vuex from 'vuex'
import oneTrDrain from './one-tr-drain.vue'
import { VueEditor } from "vue2-editor"

export default {
    components: {
        calculatorStep,
        oneTrDrain,
        VueEditor,
    },
    data() {
        return {
            currentRootId: null,
            query: '',
            elements: [],
            drainArray: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            topCategory: 'catalog/topCategory',
            calc_drain_body: 'calculator/calc_drain_body',
            calculators: 'app/calculators',
        }),
        calculatorGeneralDescription() {
            return Object.assign({}, this.calculators.calculatorDrainage.generalDescription)
        },
        drainArrayId1c() {
            return this.calc_drain_body.systems
        }
    },
    watch: {
        drainArrayId1c(val) {
            if (val.length != 0) {
                this.getDrainArray()
            }
        }
    },
    methods: {
        ...Vuex.mapActions({
            getStoreCalcDrain: 'calculator/getCalcDrain',
            searchNavbar: 'catalog/searchNavbar',
        }),
        ...Vuex.mapMutations({
            changeBodyCalcDrain: 'calculator/changeBodyCalcDrain',
            updateCalculatorDrainageGeneralDescription: 'app/updateCalculatorDrainageGeneralDescription',
        }),
        getCalcDrain(id_1c) {
            this.getStoreCalcDrain(id_1c)
        },
        getElements() {
            if (this.query == null || this.query.length < 3) {
                return
            }
            this.searchNavbar({root_id: this.calc_drain_body.root_1c_id, query: this.query, is_group: true})
                .then((response) => {
                    this.elements = response.data
                })
        },
        getDrainArray() {
            console.log('use thios func ????')
            this.searchNavbar({root_id: this.calc_drain_body.root_1c_id, id_1cs: this.drainArrayId1c, tempFrom: 'calculators admin'})
                .then((response) => {
                    this.drainArray = response.data
                })
        },
        getOneDrainName(id_1c) {
            let name = null
            this.drainArray.map((element) => {
                if (id_1c == element.id_1c) {
                    name = element.name
                }
            })
            return name
        },
        addSystem(id_1c) {
            this.changeBodyCalcDrain({type: 'systems', do: 'add', value: id_1c})
            this.query = ''
        },
        deleteSystem(id_1c) {
            this.changeBodyCalcDrain({type: 'systems', do: 'delete', value: id_1c})
        },
        updateGeneralDescription() {
            this.updateCalculatorDrainageGeneralDescription(this.calculatorGeneralDescription)
        },
    }
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>
<style lang="scss">
    .block-drainage{
        .mb-10{
            margin-bottom: 10rem;
        }
        .mt-5{
            margin-top: 5rem;
        }
        .mt-0{
            margin-top: 0;
        }
        .cl-top-option{
            margin-bottom: 10rem;

            .cl-icon-radio{
                max-width: 200px;
            
                .cl-icon-radio__btn{
                    height: 60px;
                }
            }
        }

        .block_array{
            margin-top: 20px;

            span{
                font-size: 15px;
            }
        }

        .search-field{
            width: 50%;

            .search-input{
                width: 100%;
                height: 48px;
                padding: 17px 0 17px 23px;
                background: #f5f6fa;
                color: #353b48;
                font-size: 1.2rem;
                margin: 0;
                border: 0;
                border-radius: 4px;
                font-weight: 700;
            }
            .search-submit{
                position: absolute;
                width: 18px;
                height: 18px;
                background: url('~@/img/search-button.svg') 0 0/100% 100% no-repeat;
                border: 0;
                padding: 0;
                margin: 0;
                top: 50%;
                margin-top: -9px;
                right: 26px;
            }
        }

        .block-elements{
            width: 50rem;
            
            .search-results{
                display: block;

                .search-result{
                    padding: 15px;
                    
                    .button{
                        padding: 5px;
                    }
                }
            }
        }

        .nlk-table{
            .search-field{
                width: 100%;
            }
            .left-td{
                width: 40%;
            }

            td{
                border: 1px solid #bdbec1;
            }

            .block-elements{
                position: absolute;
                background: #fff;
                z-index: 100;
                width: 63rem;

                .search-results{
                    margin-top: 0;
                    
                }
            }
        }
    }
</style>