<template>
    <section>
        <div class="nd-about-company_page">
            <div class="container-l">
                <bread-crumbs class="breadcrumbs" :pages-array="pages" :withNdProp="false" />
            </div>
            <div class="container-l">
                <div class="main_block">
                    <div class="modal-iframe">
                        <iframe 
                            src="https://vk.com/video_ext.php?oid=238807615&id=456239022&hd=2" 
                            width="853" 
                            height="480" 
                            allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" 
                            frameborder="0" 
                            allowfullscreen>
                        </iframe>
                    </div>

                    <!-- <div class="main_block_img">
                        <img class="main_block_img" src="@/assets/img/nd-ac/main_img.png" alt="">
                    </div> -->
                    <!-- <div class="main_block_headers">
                        <div class="main_block_header">
                            <p>МаякМеталл - это уникальный партнер для всех, кто ищет комплексное решение для своих задач</p>
                        </div>

                        <div class="main_block_subheader">
                            <p>Мы предлагаем клиентам полный цикл услуг, начиная с поставки продукции и заканчивая проектированием и изготовлением конструкций</p>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="container-l">
                <div class="about_block">
                    <div class="left_block">
                        <p>О нас</p>
                    </div>
                    <div class="right_block">
                        <p>
                            «МаякМеталл» – ведущий поставщик строительных материалов с 34-летним опытом. Мы предлагаем широкий ассортимент продукции: 
                            металлопрокат (профнастил, металлочерепица, сэндвич-панели, металлоконструкции), крепежные изделия. 
                        </p>

                        <p>
                            Компания имеет 17 представительств по всей территории России: Самара, Тольятти, Пенза, Пермь, Красноярск, Канск, Иркутск, а также в 
                            Казахстане и планирует продолжить свое развитие в странах ближнего зарубежья. Мы гарантируем высочайшее качество продукции благодаря 
                            сотрудничеству с ведущими производителями и поставщиками сырья. 
                        </p>
                        
                        <p>
                            «МаякМеталл» – это полный цикл услуг: от поставки материалов для розничных клиентов до проектирования, изготовления и монтажа 
                            металлоконструкций. 
                        </p>
                        
                        <p>
                            Более 1000 профессионалов работают в компании, обеспечивая индивидуальный подход к каждому проекту. Мы используем современное 
                            оборудование и передовые технологии на всех 4 производственных площадках. 
                        </p>
                        
                        <p>
                            Мы будем рады сотрудничеству и готовы помочь вам реализовать любой проект!
                        </p>
                    </div>
                </div>
            </div>
            <div class="container-l">
                <div class="progress_block progress_block_ac">
                    <div class="progress_list">
                        <div  class="progress_card">
                            <img src="@/assets/img/vacancy/people.png" alt="">
                            <p class="progress_card_header">800+ сотрудников</p>
                            <p class="progress_card_description">На данный момент в штате компании – более 800 сотрудников. И мы продолжаем развиваться.</p>
                        </div>
                        <div  class="progress_card">
                            <img src="@/assets/img/vacancy/idustrial.png" alt="">
                            <p class="progress_card_header">5 <br>заводов</p>
                            <p class="progress_card_description">Расположенных в РФ и Казахстане</p>
                        </div>
                        <div  class="progress_card">
                            <img src="@/assets/img/vacancy/office.png" alt="">
                            <p class="progress_card_header">16 <br>офисов</p>
                            <p class="progress_card_description">Продаж в России и Казахстане</p>
                        </div>
                        <div  class="progress_card">
                            <img src="@/assets/img/vacancy/material.png" alt="">
                            <p class="progress_card_header">более 150 <br>видов</p>
                            <p class="progress_card_description">Производимой продукции металлоконструкции, профлист, гладкий лист, сэндвич-панели и т.п.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-l">
                <div class="img_cards">
                    <div class="img_card">
                        <div class="half_block">
                            <p class="half_block_header">Качество продукции</p>
                            <p class="half_block_subheader">
                                Вся наша продукция произведена с использованием передовых технологий и отвечает самым высоким стандартам качества
                            </p>
                        </div>
                        <div class="half_block">
                            <img class="half_block_image" src="@/assets/img/nd-ac/img_block1.png" alt="">
                        </div>
                    </div>
                    <div class="img_card">
                        <div class="half_block">
                            <p class="half_block_header">Современное оборудование</p>
                            <p class="half_block_subheader">
                                Мы постоянно модернизируем нашу производственную базу, чтобы быть на шаг впереди конкурентов и предлагать нашим клиентам 
                                только лучшее качество продукции
                            </p>
                        </div>
                        <div class="half_block">
                            <img class="half_block_image" src="@/assets/img/nd-ac/img_block2.png" alt="">
                        </div>
                    </div>
                    <div class="img_card">
                        <div class="half_block">
                            <img class="half_block_image" src="@/assets/img/nd-ac/img_block3.png" alt="">
                        </div>
                        <div class="half_block">
                            <p class="half_block_header">Квалифицированный персонал</p>
                            <p class="half_block_subheader">
                                Благодаря колоссальному опыту нашей компании, мы гарантируем профессиональный подход к каждому проекту
                            </p>
                        </div>
                    </div>
                    <div class="img_card">
                        <div class="half_block">
                            <img class="half_block_image" src="@/assets/img/nd-ac/img_block4.png" alt="">
                        </div>
                        <div class="half_block">
                            <p class="half_block_header">Широкая география присутствия</p>
                            <p class="half_block_subheader">
                                Позволяет нам удовлетворить потребности наших клиентов по всей России и Казахстану
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-l">
                <div class="dictionary_block">
                    <p class="dictionary_header">Направление деятельности</p>
                    <div class="dictionary_block_main">
                        <div class="left_block">
                            <ul class="dictionary_list">
                                <li>Сэндвич панели</li>
                                <li>Профлист</li>
                                <li>Металлочерепица</li>
                                <li>Фасадные системы</li>
                                <li>Профиль для ГКЛ</li>
                                <li>Крепежные элементы</li>
                                <li>Фасонные элементы</li>
                                <li>Быстровозводимые здания</li>
                            </ul>
                            <ul class="dictionary_list">
                                <li>Водосточная система</li>
                                <li>Металлопрокат</li>
                                <li>Сортовой и фасонный прокат</li>
                                <li>Ограждающие конструкции</li>
                                <li>Металлоконструкции</li>
                            </ul>
                        </div>
                        <div class="right_block">
                            <img class="dictionary_main_image" src="@/assets/img/nd-ac/dictionary_img.png" alt="">
                            <img class="dictionary_redpoint_big" src="@/assets/img/nd-ac/redpoint_big.png" alt="">
                            <img class="dictionary_redpoint_small dictionary_redpoint_small_1" src="@/assets/img/nd-ac/redpoint_small.png" alt="">
                            <img class="dictionary_redpoint_small dictionary_redpoint_small_2" src="@/assets/img/nd-ac/redpoint_small.png" alt="">
                            <img class="dictionary_redpoint_small dictionary_redpoint_small_3" src="@/assets/img/nd-ac/redpoint_small.png" alt="">
                            <img class="dictionary_redpoint_small dictionary_redpoint_small_4" src="@/assets/img/nd-ac/redpoint_small.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-l">
                <div class="excursion_block">
                    <p class="excursion_block_header">3D-экскурсия по производству </p>
                    <div class="images modal-iframe">
                        <iframe 
                            src="https://vk.com/video_ext.php?oid=238807615&id=456239019&hd=2" 
                            width="853" 
                            height="480" 
                            allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" 
                            frameborder="0" 
                            allowfullscreen
                        >
                        </iframe>
                    </div>
                </div>
            </div>
            
            <!-- <div class="container container-nd">
                <div class="history_block">
                    <p class="history_block_header">ИСТОРИЯ</p>
                    <div class="history_main_block">
                        <div class="left_block">
                            <div class="history_default_text" v-html="historyMap[numCurrentHistory].text"></div>
                        </div>
                        <div class="right_block">
                            <p class="history_year_number">{{ historyMap[numCurrentHistory].year }}</p>
                            <p class="history_year_text">год</p>
                        </div>
                    </div>
                    <div class="history_years_block">
                        <div @click="changeHistoryShow('prev')" class="arrow_left_block">
                            <img src="@/assets/img/nd-ac/history_arrow_left.png" alt="">
                        </div>
                        <div class="years">
                            <img class="year_line_img" src="@/assets/img/nd-ac/history_line_img.png" alt="">

                            <div class="years_list">
                                <div 
                                    v-for="singleHistory, idx in historyMap"
                                    :key="idx" 
                                    class="single_year"
                                >
                                    <img v-if="idx == numCurrentHistory" class="red_eclipse" src="@/assets/img/nd-ac/history_red_eclipse.png" alt="">
                                    <img v-else class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number" :style="idx == numCurrentHistory ? 'color: red' : ''">{{ singleHistory.year }}</p>
                                </div>
                            </div>
                        </div>
                        <div @click="changeHistoryShow('next')" class="arrow_right_block">
                            <img src="@/assets/img/nd-ac/history_arrow_right.png" alt="">
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="container-l">
                <div class="comand_block">
                    <p class="comand_block_header">Команда</p>
                    <p class="comand_block_description">
                        В МаякМеталл мы гордимся нашей командой профессионалов, которые с каждым днем вносят свой вклад в развитие компании. 
                        Мы объединяем людей с опытом и энтузиазмом, готовых решать самые сложные задачи и предоставлять клиентам лучшие решения.
                    </p>
                    <p class="comand_block_description">
                        Каждый член нашей команды - это истинный профессионал своего дела, обладающий глубокими знаниями и практическим опытом. 
                        Мы регулярно проводим обучение и совершенствуем навыки наших специалистов, чтобы они всегда были в курсе последних 
                        тенденций и технологий.
                    </p>
                    <p class="comand_block_description">
                        Наш коллектив отличается высокой мотивацией и командным духом. Мы работаем в тесной кооперации, что позволяет нам
                        достигать отличных результатов и решать задачи любой сложности.
                    </p>
                    <div class="images">
                        <img src="@/assets/img/nd-ac/comand_img1.png" alt="">
                        <img src="@/assets/img/nd-ac/comand_img2.png" alt="">
                    </div>
                </div>
            </div>

            <div class="container-l">
                <div class="news_block">
                    <div class="header_block">
                        <p class="news_header">НОВОСТИ И СТАТЬИ</p>
                        <button @click="push('aboutcompany/news')" class="news_header_button">БОЛЬШЕ НОВОСТЕЙ</button>
                    </div>
                    <div class="news_list">
                        <div 
                            v-for="singleNews in newsFront.slice(0, 3)"
                            :key="singleNews.id"
                            @click="pushToNews(singleNews)" 
                            class="single_news"
                        >
                            <img :src="singleNews.image" alt="">
                            <p class="single_news_data">{{ formatDate(singleNews.data) }}</p>
                            <p class="single_news_name">{{ singleNews.name }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-l">
                <div class="slogan_block">
                    <p class="slogan_header">Сила в людях!</p>
                    <p class="slogan_subheader">Ждем вас в команде МаякМеталл!</p>

                    <button class="button button--primary" @click="push('vacancies')">Наши вакансии</button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Functions from '@/utils/functions'
import Vuex from 'vuex'

export default {
    mixins: [Functions],
    components: {
        breadCrumbs,
    },
    data() {
        return {
            pages: [
                {id: 0, name: "О компании", url: "aboutcompany", fullUrl: 'aboutcompany'}
            ],
            historyMap: [
                {
                    year: '1979',
                    text: '<p><span style="color: red">Торжественный пуск завода состоялся в феврале 1979 года.</span> Директором предприятия был назначен Валерий Симакин, главным технологом — Александр Пилягин. Производство начинали с профлиста, постепенно наращивая мощности и расширяя ассортимент</p>',
                },
                {
                    year: '1985',
                    text: '<p><span style="color: red">В 1985 году запустили линии по профилированию,</span> начали проектирование и ввод линий по изготовлению трехслойных панелей ППУ.</p>',
                },
                {
                    year: '1986',
                    text: '<p><span style="color: red">В 1986 году завод</span> легких металлоконструкций <span style="color: red">получил статус Сибирского промышленно-строительного объединения</span>. Предприятие не только изготавливало металлические конструкции, но и возводило из них промышленные объекты</p>',
                },
                {
                    year: '1997',
                    text: '<p><span style="color: red">Город связывал с КЗМЛК большие надежды.</span> Но... Как и большинство даже более крупных промышленных предприятий страны, завод не смог пережить экономических потрясений 90-х, и в 1997 году был объявлен банкротом.</p>',
                },
                {
                    year: '2000',
                    text: '<p>Возрождение КЗЛМК началось в 2000-м, когда предприятие влилось в группу компаний «Маяк», имеющую десятилетний успешный опыт производства металлоконструкций в новых экономических условиях. <br><span style="color: red">В 2000-М БЫЛО ЗАРЕГИСТРИРОВАНО ООО «КАНСКИЙ ЗАВОД ЛЕГКИХ МЕТАЛЛОКОН-СТРУКЦИЙ «МАЯК».</span></p>',
                },
                {
                    year: '2009',
                    text: '<p><span style="color: red">В 2009</span> группа компаний была признана <span style="color: red">победителем конкурса «Лучшие товары и услуги Сибири».</span></p>',
                },
                {
                    year: '2011',
                    text: '<p><span style="color: red">В 2011 году</span> на предприятии были освоены <span style="color: red">три основных направления производства:</span> металлоконструкций, металлопроката и сэндвич-панелей.</p>',
                },
                {
                    year: '2013',
                    text: '<p><span style="color: red">В 2013 году на заводе была установлена универсальная линия проката,</span> такая же как на предприятиях «МаякМеталл» в Поволжье и республике Казах-стан, в ассортиментную линейку были введены новые продукты: профлист МП 20, С 25, НС 35 и металлочерепица «Монтерей». Основное направление деятельности КЗЛМК не изменилось — изготовление зданий из легких металлоконструкций</p>',
                },
                {
                    year: '2020',
                    text: '<p><span style="color: red">В национальный проект «Производительность труда» завод вошел в июне 2020 года.</span> Профессиональные консультанты Федерального центра компетенций за шесть месяцев обучили рабочую группу предприятия инструментам бережливого производства, внедрение которых позволило поднять производительность труда на 30 %.</p>',
                },
                {
                    year: '2021',
                    text: '<p><span style="color: red">В 2021 году компания продолжила реализацию проекта самостоятельно</span>, уже без сопровождения федеральных специалистов, тем не менее увеличила этот показатель еще на 30 % по сравнению с 2020 годом.</p>',
                },
                {
                    year: '2023',
                    text: '<p><span style="color: red">В 2023 году завод «МаякМеталл» продолжил наращивать объемы производства и реализации.</span> Компания демонстрирует стабильный и уверенный ежегодный рост количества производимой и продукции, спрос на которую постоянно растет, что обеспечивается высоким качеством изделий</p>',
                },
            ],
            numCurrentHistory: 0,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            news: 'ui_news/news',
            newsFront: 'ui_news/newsFront',
        }),
    },
    mounted() {
        this.getNews({})
    },
    methods: {
        ...Vuex.mapActions({
            getNews: 'ui_news/getNews',
        }),
        openLink() {
            window.open('https://perm.hh.ru/search/vacancy?from=employerPage&employer_id=41770&hhtmFrom=employer', '_blank')
        },
        changeHistoryShow(type) {
            if (type == 'next') {
                if (this.numCurrentHistory == this.historyMap.length - 1) {
                    this.numCurrentHistory = 0
                    return
                }
                this.numCurrentHistory += 1
            }else if (type == 'prev') {
                if (this.numCurrentHistory == 0) {
                    this.numCurrentHistory = this.historyMap.length - 1
                    return 
                }
                this.numCurrentHistory -= 1
            }
        },
        formatDate(date) {
            var splited_date = ''
            var result_date = ''
            if (typeof date == 'string' && date.includes('-')) {
                splited_date = date.split('-')
                result_date = splited_date[2] + '.' + splited_date[1] + '.' + splited_date[0]
            }else{
                result_date = date
            }
            return result_date
        },
        pushToNews(news) {
            if (news.link) {
                this.push(news.link)
            }else{
                this.push('aboutcompany/single/'+ news.id)
            }
        },
    },
}
</script>
<style lang="scss">
    .progress_block_ac{
        .progress_list{
            justify-content: space-between !important;
            gap: unset !important;

            .progress_card{
                width: 23% !important;
                padding: 40px 40px !important;

                .progress_card_header{
                    font-size: 2.3rem !important;
                    line-height: 2.8rem !important;
                }

                .progress_card_description{
                    font-size: 1.3rem !important;
                }
            }
        }
    }
</style>