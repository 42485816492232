<template>
    <main class="main contacts-front-page">
        <div class="section section--no-pt section--no-pb">
            <div class="container-l">
                <div class="inner" style="padding-left: 0;">
                    <div class="heading">
                        <div class="heading-section">
                            <h2>Контакты</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section--no-pt">
            <div class="container-l">
                <div v-if="regionCityArray.length != 0" class="cities_filter_block">
                    <button 
                        v-for="filter, index in regionCityArray"
                        :key="index"
                        class="city_filter_button"
                        :class="choosenCity == filter.value ? '-active' : ''"
                        @click="choosenCity = filter.value"
                    >
                        {{ filter.name }}
                    </button>
                </div>

                <div class="regions_contacts_block">
                    <div class="single_region_block">
                        <p v-if="!choosenCity" class="region_block_header">Россия</p>
                        <div v-for="ruCities, idx in regionCityMap" :key="idx + 'a'" v-show="idx != 'H2018'">
                            <div style="margin-bottom: 40px;" v-for="(cityObj, index) in ruCities" :key="index + 'b'" v-show="(choosenCity && choosenCity == index) || !choosenCity">
                                <p v-if="!choosenCity" class="city_block_header">{{ index }}</p>
                                <div v-for="address, indexru in cityObj" :key="indexru + 'c'">
                                    <div class="map-contact">
                                        <div class="map-contact-title">
                                            <span v-if="checkNameInAddressTypes(address.is_type, 'isMainOffice')" style="color: #ED1C24">Головной офис</span>
                                            <span v-if="checkNameInAddressTypes(address.is_type, 'isMainOffice')">, </span>
                                            {{ getHeaderAddress(address.is_type) }}
                                        </div>
                                        <div class="map-contact-block">
                                            <div class="map-contact-left-part">
                                                <div class="map-contact-address">
                                                    {{ address.city }}
                                                    <span v-if="address.city && address.street">, </span>
                                                    {{ address.street }}
                                                </div>
                                                <div class="map-contact-time">
                                                    <b>Время работы:</b>
                                                    <br>
                                                    <p class="workin_hours_text">
                                                        {{ address.working_hours }} 
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="map-contact-right-part">
                                                <div v-if="address.email" class="map-contact-email" style="margin-bottom: 18px;">
                                                    <i class="fa fa-envelope" style="padding-right: 20px;"></i>
                                                    <a class="email" :href="'mailto:' + address.email" style="font-size: 1.6rem;">{{ address.email }}</a>
                                                </div>
                                                <div v-if="address.phone" class="map-contact-phone" style="margin-bottom: 30px;">
                                                    <i class="fa fa-phone" style="padding-right: 20px;"></i>
                                                    <span v-for="onePhone, i in splitPhones(address.phone)" :key="i">
                                                        <a class="phone" :href="'tel:' + onePhone" style="font-size: 1.6rem; margin-right: 16px;">{{ onePhone }}</a>
                                                    </span>
                                                </div>
                                                <p v-if="address.link" class="contacts_link" @click="openMapBlank(address.link)">Показать на карте</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="single_region_block">
                        <p v-if="!choosenCity" class="region_block_header">Республика Казахстан</p>
                        <div v-for="ruCities, idx in regionCityMap" :key="idx + 'd'" v-show="idx == 'H2018'">
                            <div style="margin-bottom: 40px;" v-for="(cityObj, index) in ruCities" :key="index + 'f'" v-show="(choosenCity && choosenCity == index) || !choosenCity">
                                <p v-if="!choosenCity" class="city_block_header">{{ index }}</p>
                                <div v-for="address, indexkz in cityObj" :key="indexkz + 'e'">
                                    <div class="map-contact">
                                        <div class="map-contact-title">
                                            <span v-if="checkNameInAddressTypes(address.is_type, 'isMainOffice')" style="color: #ED1C24">Головной офис</span>
                                            <span v-if="checkNameInAddressTypes(address.is_type, 'isMainOffice')">, </span>
                                            {{ getHeaderAddress(address.is_type) }}
                                        </div>
                                        <div class="map-contact-block">
                                            <div class="map-contact-left-part">
                                                <div class="map-contact-address">
                                                    {{ address.city }}
                                                    <span v-if="address.city && address.street">, </span>
                                                    {{ address.street }}
                                                </div>
                                                <div class="map-contact-time">
                                                    <b>Время работы:</b>
                                                    <br>
                                                    <p class="workin_hours_text">
                                                        {{ address.working_hours }} 
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="map-contact-right-part">
                                                <div v-if="address.email" class="map-contact-email" style="margin-bottom: 18px;">
                                                    <i class="fa fa-envelope" style="padding-right: 20px;"></i>
                                                    <a class="email" :href="'mailto:' + address.email" style="font-size: 1.6rem;">{{ address.email }}</a>
                                                </div>
                                                <div v-if="address.phone" class="map-contact-phone" style="margin-bottom: 30px;">
                                                    <i class="fa fa-phone" style="padding-right: 20px;"></i>
                                                    <span v-for="onePhone, i in splitPhones(address.phone)" :key="i">
                                                        <a class="phone" :href="'tel:' + onePhone" style="font-size: 1.6rem; margin-right: 16px;">{{ onePhone }}</a>
                                                    </span>
                                                </div>
                                                <p v-if="address.link" class="contacts_link" @click="openMapBlank(address.link)">Показать на карте</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <call-back-form style="margin-bottom: 40px;" :isContainerNd="false" />

            <div class="container-l offer_contact_block">
                <img :src="firstBanner.link_image" alt="">
            </div>
            
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import CallBackForm from '@/components/form-callback.vue'

export default {
    components: {
        CallBackForm,
    },
    data() {
        return {
            center: [ 52.56543518842296, 70.05213952539054 ],
            zoom: 4, 
            cities: [],
            selectedRegion: null,
            selectedCity: null,

            settings: {
                apiKey: '72b24e1d-06ad-424f-af9b-44d28d93d4d6',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            },
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/img/map-marker.svg'),
                imageSize: [43, 43],
                imageOffset: [0, 0],
            },

            choosenCity: '',
        }
    },
    computed: {
        ...Vuex.mapGetters({
            contacts: 'app/contacts',
            regions: 'dictionary/regions',
            region: 'auth/city',
            topLevelCategory: 'dictionary/topLevelCategory',
            offices: 'ui_contacts/offices',
            ui_contacts: 'ui_contacts/contacts',
            banners: 'ui_contacts_banner/banners',
        }),
        currentAddresses() {
            let addresses = []
            if (this.contacts.addresses) {
                this.contacts.addresses.map((element) => {
                    if (element.region_id_1c == this.region) {
                        addresses.push(element)
                    }
                })
            }
            return addresses
        },
        curentRegionsFilter() {
            let regions = []
            regions.push({id: '', name: 'Все'})
            this.topLevelCategory.map((element) => {
                regions.push({id: element.id_1c, name: element.name})
                   
            })
            return regions
        },
        curentCitiesFilter() {
            let cities = []
            let id = 10
            cities.push({id: '', name: 'Все'})
            this.currentAddresses.map((element) => {
                if (!this.selectedRegion) {
                    if (!this.isIssetInArray(element.city, cities)) {
                        cities.push({id: id, name: element.city})
                        id += 10
                    }
                }else{
                    if (this.selectedRegion == element.region_id_1c) {
                        if (!this.isIssetInArray(element.city, cities)) {
                            cities.push({id: id, name: element.city})
                            id += 10
                        }
                    }
                }
            })
            return cities
        },
        regionCityMap() {
            var map = {}
            var array = this.ui_contacts
            array.map((element) => {
                if (!map[element.region]) {
                    map[element.region] = {[element.city]: [element]}
                }else{
                    if (!map[element.region][element.city]) {
                        map[element.region][element.city] = [element]
                    }else{
                        map[element.region][element.city].push(element)
                    }
                }
            })
            return map
        },
        regionCityArray() {
            var array_result = []
            var array = this.ui_contacts
            array_result.push({name: 'Все офисы', value: ''})
            array.map((element) => {
                if (!this.checkIssetElementInArray(array_result, element.city)) {
                    let region_num = element.region.match(/\d+/)
                    let result = parseInt(region_num[0], 10)
                    array_result.push({name: element.city, value: element.city, region: result})
                }
            })
            array_result = array_result.sort((a, b) => Number(a.region) - Number(b.region))
            return array_result
        },
        firstBanner() {
            var first_banner = {}
            if (this.banners.length != 0) {
                first_banner = this.banners[0]
            }
            return first_banner
        },
    },
    watch: {
        contacts: {
            handler: function () {
                this.setCurrentCities()
            },
            deep: true
        },
        selectedRegion() {
            this.selectedCity = null

            let selectCityElement = document.getElementById('contactsPageSelectCity')
            let single = selectCityElement.getElementsByClassName('choices__item choices__item--selectable')
            single[0].innerHTML = 'Все'
        }
    },
    created() {
        if (this.contacts.addresses) {
            this.setCurrentCities()
        }
    },
    mounted() {
        this.geolocation()
        this.getContacts()
        this.getBanners()
    },
    methods: {
        ...Vuex.mapMutations({
            filterAddressRegion: 'app/filterAddressRegion',
            filterAddressRegionCity: 'app/filterAddressRegionCity',
        }),
        ...Vuex.mapActions({
            geolocation: 'ui_contacts/geolocation',
            getContacts: 'ui_contacts/getContacts',
            getContactsBanner: 'ui_contacts_banner/getContactsBanner',
        }),
        getBanners() {
            this.getContactsBanner({
                root_id: this.region,
            })
        },
        checkIssetElementInArray(array, value) {
            var isset = false
            array.map((element) => {
                if (element.name == value) {
                    isset = true
                }
            })
            return isset
        },
        setRegionsCityMap() {
            var array = this.ui_contacts
            this.regCityMap.asd = '' 
            array.map((element) => {
                this.regionCityMap.asd = '' 
                if (this.regionCityMap[element.region]) {
                    this.regionCityMap[element.region] = ""
                }
            })
        },
        checkNameInAddressTypes(types, type) {
            var isset = false
            if (Array.isArray(types) && types.length != 0) {
                types.map((element) => {
                    if (element == type) {
                        isset = true
                    }
                })
            }
            return isset
        },
        splitPhones(phones) {
            let splited = phones.split(',')
            return splited
        },
        isIssetInArray(val, array) {
            let isset = false
            array.map((element) => {
                if (val == element.name) {
                    isset = true
                }
            })
            return isset
        },
        getNameRegionById1c(id_1c) {
            let name = 'Регион'
            this.topLevelCategory.map((element) => {
                if (element.id_1c == id_1c) {
                    name = element.name
                }
            })
            return name
        },
        getNameCityById(id) {
            let name = null
            this.curentCitiesFilter.map((element) => {
                if (element.id == id) {
                    name = element.name
                }
            })
            return name
        },
        getHeaderAddress(types) {
            let str = ''
            let countTrueValues = 0
            let firstSymbol = ', '
            let secondSymbol = ' и '
            var isOffice = false
            var isProduction = false
            var isWarehouse = false

            if (Array.isArray(types) && types.length > 0) {
                types.map((element) => {
                    if (element == 'isOffice') {
                        isOffice = true
                        countTrueValues++
                    }else if (element == 'isProduction') {
                        isProduction = true
                        countTrueValues++
                    }else if (element == 'isWarehouse') {
                        isWarehouse = true
                        countTrueValues++
                    }   
                })
            }

            if (countTrueValues == 1) {
                if (isOffice) {
                    str = 'Офис продаж'
                }
                if (isProduction) {
                    str = 'Производство'
                }
                if (isWarehouse) {
                    str = 'Склад'
                }
            }else if (countTrueValues == 2) {
                if (isOffice && isProduction) {
                    str = 'Офис продаж' + secondSymbol + 'производство'
                }else if (isOffice && isWarehouse) {
                    str = 'Офис продаж' + secondSymbol + 'склад'
                }else if (isProduction && isWarehouse) {
                    str = 'Производство' + secondSymbol + 'склад'
                }
            }else if (countTrueValues == 3) {
                str = 'Офис продаж' + firstSymbol + 'производство' + secondSymbol + 'склад'
            }

            return str
        },
        setCurrentCities() {
            this.cities = []
            this.contacts.addresses.map((element, i) => {
                if (this.selectedRegion != null) {
                    if (element.region == this.selectedRegion) {
                        this.cities.push({id: (i + 1) + '0', name: element.city})
                    }
                }else{
                    this.cities.push({id: (i + 1) + '0', name: element.city})
                }
            })
        },
        changeFilterCity() {
            if (!this.selectedCity) {
                this.filterAddressRegion(this.selectedRegion)
                return
            }

            this.filterAddressRegionCity({region: this.selectedRegion, city: this.getNameCityById(this.selectedCity)})
        },
        getObjectAddressForBalloon(obj) {
            let object = {header: '', body: ''}
            object.header = this.getNameRegionById1c(obj.region_id_1c)
            object.body += '<p style="margin-bottom: 0">' + obj.city + '</p>'
            object.body += '<p style="margin-bottom: 0">' + obj.street + '</p>'
            object.body += '<p style="margin-bottom: 0">' + obj.working_hours + '</p>'
            return object
        },
        getTextAddressForHint(obj) {
            let text = ''
            text += '<p style="margin-bottom: 0"><b>' + this.getNameRegionById1c(obj.region_id_1c) + '</b></p>'
            text += '<p style="margin-bottom: 0">' + obj.city + '</p>'
            text += '<p style="margin-bottom: 0">' + obj.street + '</p>'
            text += '<p style="margin-bottom: 0">' + obj.working_hours + '</p>'
            return text
        },
        openMapBlank(link) {
            window.open(link, '_blank')
        },
    },
}
</script>
<style lang="css">
    #map{
        height: 520px !important;
    }
    .catalog-controls--contacts .choices__list--dropdown .choices__list{
        max-height: 100%;
    }
</style>
<style lang="scss">
    .contacts_link{
        color: #3384FF;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.4rem;
        
        &:hover{
            cursor: pointer;
        }
    }

    .cities_filter_block{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 40px;

        .city_filter_button{
            padding: 10px 20px;
            border: unset;
            border-radius: 20px;

            background-color: #F5F6FA;
            color: black;

            // font-weight: 400;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.4rem;

            &:hover{
                background-color: #ed1c24;
                color: white;
                font-weight: 700;
            }
        }

        .-active{
            background-color: #ed1c24;
            color: white;
            font-weight: 700;
        }
    }

    .map-contact{
        box-shadow: 0px 4px 10px 0px #0000000D;
        border: 1px solid #0000001A;
        margin-bottom: 20px;
        padding: 20px;

        .map-contact-block{
            display: flex;
            flex-wrap: nowrap;

            .map-contact-left-part{
                width: 50%;

                .workin_hours_text{
                    margin-bottom: 0;
                }
            }

            .map-contact-right-part{
                width: 50%;
            }
        }
    }

    .regions_contacts_block{
        .single_region_block{
            .region_block_header{
                font-weight: 700;
                font-size: 2.2rem;
                line-height: 2.8rem;
                color: #718093;
                margin-bottom: 20px;
            }

            .city_block_header{
                font-weight: 700;
                font-size: 2.2rem;
                line-height: 2.8rem;
                margin-bottom: 20px;
            }
        }
    }

    .contacts-front-page{
        margin-top: 50px;

        .offer_contact_block{
            margin-bottom: 50px;
            img{
                width: 100%;
            }
        }
    }
</style>