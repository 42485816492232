<template>
    <div class="news_single_block">
        <h4>Новость №{{ index+1 }}</h4>

        <label for="">Регион</label>
        <select 
            v-model="editElement.root_id" 
            @change="update"
        >
            <option value="">Выберите регион</option>
            <option 
                v-for="(category, idx) in topLevelCategory"
                :key="idx"
                :value="category.id_1c"
            >
                {{ category.name }}
            </option>
        </select>

        <label for="">Название</label>
        <input type="text" v-model="editElement.name" @change="update">

        <label for="">Описание</label>
        <vue-editor 
            v-model="editElement.description"
            @text-change="update"
        />

        <label for="">Дата</label>
        <input type="date" v-model="editElement.data" @change="update">

        <label for="">Ссылка на страницу</label>
        <input type="text" v-model="editElement.link" @change="update">

        <label for="">Изображение</label>
        <input type="text" v-model="editElement.image" @change="update">
        <img v-if="editElement.image != null" :src="editElement.image" alt="" class="coating_main_img">
        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'acnewsUpload' + editElement.id" 
                    type="file" 
                    @change="uploadImg"
                >
            </label>
            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
        </div>

        <button @click="deleteNews(editElement.id)">Удалить</button>
    </div>
</template>
<script>
import Vuex from 'vuex'
import { VueEditor } from "vue2-editor"

export default {
    components: {
        VueEditor,
    },
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            updateNews: 'ui_news/updateNews',
            deleteNews: 'ui_news/deleteNews',
        }),
        update() {
            this.updateNews(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['acnewsUpload' + this.editElement.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editElement.image = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
    },
}
</script>
<style lang="scss">
    .news_single_block{
        width: 80%;
    }
</style>