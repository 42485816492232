<template>
    <section class="coatings-page">
        <div class="coating-single-page">
            <div class="container-l">
                <bread-crumbs v-if="isShowBreadcrumbs" class="breadcrumbs" :pages-array="pages" :withNdProp="false" />
            </div>
            <div class="page container-l">
                <div class="navigation_block">
                    <p class="navigation_header">Покрытия</p>
                    <ul class="navigation_list">
                        <li 
                            v-for="coating, idx in coatings"
                            :key="idx"
                            :class="currantCoatingId == coating.id ? 'active' : ''"
                            @click="push('coatings/' + coating.id)"
                        >
                            {{ coating.name }}
                        </li>
                    </ul>
                </div>
                <div class="main_block">
                    <div class="main_description">
                        <div class="left_part">
                            <img v-if="mainImage" :src="mainImage" alt="">
                            <img v-else src="@/assets/img/coatings/single_1.png" alt="">
                        </div>
                        <div v-if="currentCoating" class="right_part">
                            <p class="coating_header">{{ currentCoating.name }}</p>
                            <p class="coating_description">
                                {{ currentCoating.description }}
                            </p>
                        </div>
                    </div>
                    <div class="colors_block_row">
                        <p class="color_header">Палитра цветов: </p>
                        <div class="colors_block">
                            <div v-for="color, idx in currentColors" :key="idx" class="color_block">
                                <div class="left_part" :style="color.code == '#ffffff' ? 'background-color:' + color.code + '; border: 1px solid black' : 'background-color:' + color.code">
                                    <img v-if="color.code != '#ffffff'" src="@/assets/img/coatings/vector_color.png" alt="" class="color_vector">
                                    <img v-else src="@/assets/img/coatings/vector_color_black.png" alt="" class="color_vector">
                                    <img v-if="color.image" :src="color.image" alt="">
                                </div>
                                <div>
                                    <p class="color_name">{{ color.name }}</p>
                                    <p class="color_subname">{{ color.sub_name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="char_block">
                        <p class="char_header">Характеристики покрытия: </p>
                        <div class="char" v-for="char, idx in currentChars" :key="idx + 'd'">
                            <div class="left_part">
                                <img src="@/assets/img/coatings/reddot_1.png" alt="" class="reddot">
                                <p class="char_name">{{ char.name }}</p>
                            </div>
                            <p v-if="char.name != 'Коррозионная стойкость' && char.name != 'Износостойкость покрытия'" class="char_value">{{ char.value }}</p>
                            <div v-else class="eclipse_block">
                                <img 
                                    v-for="eclipse, idx in getEclipseArray(char.id).filled"
                                    :key="idx + 'a'"
                                    src="@/assets/img/coatings/filled_eclipse.png" 
                                    alt=""
                                >
                                <img 
                                    v-for="eclipse, idx in getEclipseArray(char.id).half"
                                    :key="idx + 'b'"
                                    src="@/assets/img/coatings/half_eclipse.png" 
                                    alt=""
                                >
                                <img 
                                    v-for="eclipse, idx in getEclipseArray(char.id).empty"
                                    :key="idx + 'c'"
                                    src="@/assets/img/coatings/empty_eclipse.png" 
                                    alt=""
                                >
                            </div>
                        </div>
                        <div class="char_description">
                            Текстура и оттенок покрытия могут отличаться от образцов в связи с технологическими особенностями производства.
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="gallery_slider">
                    <slider 
                        v-if="currentCoating.link_image && currentCoating.link_image.length != 0" 
                        :listImages="currentCoating.link_image" 
                    />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import slider from './slider.vue'
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    components: {
        breadCrumbs,
        slider,
    },
    data () {
        return {
            pages: [],
            isShowBreadcrumbs: false,
        }
    },
    watch: {
        currentCoating(val) {
            this.pages = []
            this.pages.push({id: 0, name: "Покрытия", url: "coatings", fullUrl: 'coatings'})
            this.pages.push({id: 0, name: val.name, url: "coatings", fullUrl: 'coatings/' + val.id})
            this.isShowBreadcrumbs = true
        }
    },
    computed: {
        ...Vuex.mapGetters({
            coatings: 'coatings/coatings',
            colors: 'coatingColors/colors',
            chars: 'coatingChars/chars',
        }),
        currantCoatingId() {
            return this.$route.params.id
        },
        currentCoating() {
            var current = {}
            var coating_id = this.$route.params.id
            this.coatings.map((element) => {
                if (element.id == coating_id) {
                    current = element
                }
            })
            return current
        },
        mainImage() {
            var image = null
            if (this.currentCoating.image && this.currentCoating.image.length != 0) {
                image = this.currentCoating.image[0]
            }
            return image
        },
        currentColors() {
            var colors = []
            var map = {}
            this.colors.map((element) => {
                map[element.id] = element
            })
            if (this.currentCoating.list_colors) {
                this.currentCoating.list_colors.map((obj) => {
                    var value_obj = obj
                    if (typeof obj == 'string') {
                        value_obj = JSON.parse(obj)
                    }
                    colors.push(map[value_obj.color_id])  
                })
            }
            return colors
        },
        currentChars() {
            var chars = []
            var map = {}
            this.chars.map((element) => {
                map[element.id] = element
            })
            if (this.currentCoating.list_chars) {
                this.currentCoating.list_chars.map((obj) => {
                    var value_obj = obj
                    if (typeof value_obj == 'string') {
                        value_obj = JSON.parse(obj)
                    }
                    var new_obj = {
                        name: map[value_obj.char_id].name,
                        value: obj.value,
                        sort: obj.sort,
                        id: map[value_obj.char_id].id
                    }
                    chars.push(new_obj)   
                })
            }
            return chars
        },
    },
    mounted() {
        this.getColors()
        this.getChars()
        this.getCoatings()
    },
    methods: {
        ...Vuex.mapActions({
            getCoatings: 'coatings/search',
            getColors: 'coatingColors/search',
            getChars: 'coatingChars/search',
        }),
        getEclipseArray(id) {
            var array = {filled: 0, half: 0, empty: 5}
            var count_filled = 0
            this.currentCoating.list_chars.map((element) => {
                var value_obj = element
                if (typeof value_obj == 'string') {
                    value_obj = JSON.parse(element)
                }
                if (value_obj.char_id == id) {
                    count_filled = value_obj.value
                }
            })
            
            if (count_filled == 1) {
                array.filled = 1
                array.half = 0
                array.empty = 4
            }

            if (count_filled == '1,5' || count_filled == '1.5') {
                array.filled = 1
                array.half = 1
                array.empty = 3
            }

            if (count_filled == 2) {
                array.filled = 2
                array.half = 0
                array.empty = 3
            }

            if (count_filled == '2,5' || count_filled == '2.5') {
                array.filled = 2
                array.half = 1
                array.empty = 2
            }

            if (count_filled == 3) {
                array.filled = 3
                array.half = 0
                array.empty = 2
            }

            if (count_filled == '3,5' || count_filled == '3.5') {
                array.filled = 3
                array.half = 1
                array.empty = 1
            }

            if (count_filled == 4) {
                array.filled = 4
                array.half = 0
                array.empty = 1
            }

            if (count_filled == '4,5' || count_filled == '4.5') {
                array.filled = 4
                array.half = 1
                array.empty = 0
            }

            if (count_filled == 5) {
                array.filled = 5
                array.half = 0
                array.empty = 0
            }

            return array
        },
    },
}
</script>