<template>
  <section>
    <div class="coatings-page">
        <div class="banner container-fluid">
            <div class="container-l">
                <bread-crumbs class="breadcrumbs" :pages-array="pages" :woContainer="true" :withNdProp="false" />
            </div>
            <img v-if="mainBanner" :src="mainBanner" alt="">
            <img v-else src="@/assets/img/coatings/banner.png" alt="">
        </div>
        <div class="coatings-card-block container-l">
            <p class="block-header">КОЛЛЕКЦИЯ ПОКРЫТИЙ</p>
            <div class="coating-cards">
                <div class="c-card" v-for="coating, idx in coatings" :key="idx + 'a'" @click="toSingleCard(coating.id)" v-show="coating.image[0]">
                    <div class="left_part">
                        <img class="main_img" :src="coating.image[0]" alt="">
                        <img class="border_img" src="@/assets/img/coatings/border_frame.png" alt="">
                    </div>
                    <div class="right_part">
                        <img class="line_img" src="@/assets/img/coatings/line_1.png" alt="">
                        <p class="header">{{ coating.name }}</p>
                        <p class="description_text">
                            {{ coating.short_description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="table_char_block container-l">
            <div class="custom_table">
                <div class="headers">
                    <div></div>
                    <div v-for="coating, idx in coatings" :key="idx + 'b'">
                        {{ coating.name }}
                    </div>
                </div>
                <div v-for="char, index in chars" :key="index  + 'c'" class="rows" :class="(index % 2) == 1 ? 'bg_grey' : ''">
                    <div>{{ char.name }}</div>
                    <div v-for="coating, idx in coatings" :key="idx  + 'd'">
                        <span v-if="char.name != 'Коррозионная стойкость' && char.name != 'Износостойкость покрытия'">
                            {{ getCharValueOfCoating(coating.id, char.id) }}
                        </span>
                        <div v-else class="eclipse_block">
                            <img 
                                v-for="eclipse, idx in getEclipseArray(coating.id, char.id).filled"
                                :key="idx  + 'aa'"
                                src="@/assets/img/coatings/filled_eclipse.png" 
                                alt=""
                            >
                            <img 
                                v-for="eclipse, idx in getEclipseArray(coating.id, char.id).half"
                                :key="idx + 'bb'"
                                src="@/assets/img/coatings/half_eclipse.png" 
                                alt=""
                            >
                            <img 
                                v-for="eclipse, idx in getEclipseArray(coating.id, char.id).empty"
                                :key="idx + 'cc'"
                                src="@/assets/img/coatings/empty_eclipse.png" 
                                alt=""
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>
<script>
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Functions from '@/utils/functions'
import Vuex from 'vuex'

export default {
    mixins: [Functions],
    components: {
        breadCrumbs,
    },
    data() {
        return {
            pages: [
                {id: 0, name: "Покрытия", url: "coatings", fullUrl: 'coatings'}
            ],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            coatings: 'coatings/coatings',
            coatingsMain: 'coatings/coatingsMain',
            colors: 'coatingColors/colors',
            chars: 'coatingChars/chars',
        }),
        mainBanner() {
            var image = null
            if (this.coatingsMain[0]) {
                image = this.coatingsMain[0].image
            }
            return image
        },
    },
    mounted() {
        this.getColors()
        this.getChars()
        this.getCoatings()
        this.getCoatingsMain()
    },
    methods: {
        ...Vuex.mapActions({
            getCoatings: 'coatings/search',
            getCoatingsMain: 'coatings/searchMain',
            getColors: 'coatingColors/search',
            getChars: 'coatingChars/search',
        }),
        toSingleCard(id) {
            this.push('coatings/' + id)
        },
        getCharValueOfCoating(coating_id, char_id) {
            var coating = null
            var char = null
            var value = ''
            this.coatings.map((element) => {
                if (element.id == coating_id) {
                    coating = element
                }
            })
            
            if (coating != null) {
                coating.list_chars.map((el) => {
                    if (el.char_id == char_id) {
                        char = el
                    }
                })
            }
             
            if (char != null) {
                value = char.value
            }   
            return value
        },
        getEclipseArray(coating_id, id) {
            var array = {filled: 0, half: 0, empty: 5}
            var count_filled = 0
            var currentCoating = null

            this.coatings.map((obj) => {
                if (obj.id == coating_id) {
                    currentCoating = obj
                }
            })

            if (currentCoating) {
                currentCoating.list_chars.map((element) => {
                    var value_obj = element
                    if (typeof value_obj == 'string') {
                        value_obj = JSON.parse(element)
                    }
                    if (value_obj.char_id == id) {
                        count_filled = value_obj.value
                    }
                })
            }

            if (count_filled == 1) {
                array.filled = 1
                array.half = 0
                array.empty = 4
            }

            if (count_filled == '1,5' || count_filled == '1.5') {
                array.filled = 1
                array.half = 1
                array.empty = 3
            }

            if (count_filled == 2) {
                array.filled = 2
                array.half = 0
                array.empty = 3
            }

            if (count_filled == '2,5' || count_filled == '2.5') {
                array.filled = 2
                array.half = 1
                array.empty = 2
            }

            if (count_filled == 3) {
                array.filled = 3
                array.half = 0
                array.empty = 2
            }

            if (count_filled == '3,5' || count_filled == '3.5') {
                array.filled = 3
                array.half = 1
                array.empty = 1
            }

            if (count_filled == 4) {
                array.filled = 4
                array.half = 0
                array.empty = 1
            }

            if (count_filled == '4,5' || count_filled == '4.5') {
                array.filled = 4
                array.half = 1
                array.empty = 0
            }

            if (count_filled == 5) {
                array.filled = 5
                array.half = 0
                array.empty = 0
            }

            return array
        },
    },
}
</script>