<template>
    <div 
        class="section section--brands home_brands_block"
        :class="additionalClass"
    >
        <div class="container-l">
            
            <h2>Наши клиенты</h2>
            
            <swiper :options="swiperOption" ref="swiperThumbs">
                <swiper-slide v-for="(client , i) in uiClients" :key="i"> 
                    <div 
                        class="item--brands"
                    >
                        <img :src="client.link" alt="">
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
    props: {
        additionalClass: {
            type: String,
            default: "",
        },
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOption: {
                loop: true,
                spaceBetween: 0,
                slidesPerView: 2,
                slideToClickedSlide: true,
                breakpoints: {
                    768: {
                        slidesPerView: 3.1
                    },
                    1260: {
                        slidesPerView: 4.1
                    },
                    1680: {
                        slidesPerView: 4.1
                    },
                    1770: {
                        slidesPerView: 4.6
                    },
                },
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            main: 'app/main',
            currentWindowWidth: 'app/currentWindowWidth',
            uiClients: 'ui_client/clients',
        }),
    },
    created() {
        this.setAmountSlides()
    },
    methods: {
        setAmountSlides() {
            if (this.currentWindowWidth >= 768 && this.currentWindowWidth <= 1600) {
                this.swiperOption.slidesPerView = 4
            }else if (this.currentWindowWidth > 1600) {
                this.swiperOption.slidesPerView = 5
            }
        },
    },
}
</script>
<style lang="scss">
  .swiper-slide{
    .item--brands{
      width: 100%;
      filter: grayscale(100%); 

      img{
        width: 75%;
      }

      &:hover{
        filter: none;
      }
    }
  }
</style>