<template>
  <div class="good">
    <span 
        class="index__del" 
        @click="deleteProduct(editElement.id)"
    >
        Удалить X
    </span>
    <h5>Товар №{{ index + 1 }}</h5>
    <label>ID товара из 1c</label>
    <input 
        v-model="editElement.id_1c"
        type="text" 
        placeholder="ID товара из 1c"
        @change="update"
    >
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {

        }
    },
    computed: {
        editElement() {
            return Object.assign({}, this.element)
        }
    },
    methods: {
        ...Vuex.mapActions({
            deleteProduct: 'ui_products/deleteProduct',
            updateProduct: 'ui_products/updateProduct',
        }),
        update() {
            this.updateProduct(this.editElement)
        },
    },
}
</script>

<style lang="scss">
    .good{
        display: grid;
        margin-top: 15px;
    }
</style>