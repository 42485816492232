import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import app from './app'
import dictionary from './dictionary'
import videomodal from './videoModal'
import appmodal from './modals/appModal'
import offers from './offers/offers'
import basket from './basket'
import catalog from './catalog'
import search from './search'
import calculator from './calculator'
import reports from './reports'
import permissions from './permissions'
import users from './users'
import logs from './logs'
import utm from './utm'
import companies from './companies'

import ui_category from './ui/category'
import ui_client from './ui/client'
import ui_consultation from './ui/consultation'
import ui_cost from './ui/cost'
import ui_document from './ui/document'
import ui_network from './ui/network'
import ui_object from './ui/object'
import ui_partner from './ui/partner'
import ui_promotion from './ui/promotion'
import ui_reason from './ui/reason'
import ui_review from './ui/review'
import ui_service from './ui/service'
import ui_slide from './ui/slide'
import ui_support from './ui/support'
import ui_card_address from './ui/card_address'
import ui_contacts from './ui/contacts'
import ui_contacts_banner from './ui/contacts_banner'
import ui_news from './ui/news'
import ui_products from './ui/popular_products'

import coatingColors from './coatings/colors'
import coatingChars from './coatings/chars'
import coatings from './coatings/coatings'
import vacancy from './vacancy/vacancy'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

export default new Vuex.Store({
  modules: {
    auth,
    app,
    dictionary,
    videomodal,
    appmodal,
    offers,
    basket,
    catalog,
    search,
    calculator,
    reports,
    permissions,
    users,
    logs,
    utm,
    companies,

    ui_category,
    ui_client,
    ui_consultation,
    ui_cost,
    ui_document,
    ui_network,
    ui_object,
    ui_partner,
    ui_promotion,
    ui_reason,
    ui_review,
    ui_service,
    ui_slide,
    ui_support,
    ui_card_address,
    ui_contacts,
    ui_contacts_banner,
    ui_news,
    ui_products,

    coatingColors,
    coatingChars,
    coatings,
    vacancy,
  },
  strict: debug,
})
