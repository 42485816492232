<template>
    <main class="main">
          <bread-crumbs :pages-array="pagesOffers" :withNdProp="false" />
          <div class="section section--no-pt section--no-pb">
            <div class="container-l">
              <div class="inner">
                <div class="heading">
                  <div class="heading-section">
                    <h2>Акции и спецпредложения</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-container slider slider--hero-offers">
              <div class="swiper-wrapper">
                
                <div 
                  class="swiper-slide" 
                  v-for="(offer, idx) in currentPromotions"
                  :key="idx + 'a'"
                  :style="'background-image: url(' + offer.linkimg + ')'"
                  v-show="idx + 1 == currentSlide"
                >
                  <div class="container-l">
                    <div class="inner">
                      <div class="slider-title">{{ offer.name }}</div>
                      <div 
                        class="slider-description" 
                        v-if="offer.close_date"
                      >
                        <b>Срок действия акции:</b> 
                        до {{ getNormalFormatedDate(offer.close_date) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="slider-controls">
                <div 
                  class="swiper-button-prev"
                  @click="prevSlide"
                />
                <div class="swiper-pagination">
                    <span 
                      class="swiper-pagination-bullet " 
                      :class="{'swiper-pagination-bullet-active' : idx + 1 == currentSlide}"
                      tabindex="0" 
                      role="button"
                      v-for="(offer, idx) in currentPromotions"
                      :key="idx + 'b'"
                      @click="currentSlide = idx + 1"
                    />
                </div>
                <div 
                  class="swiper-button-next"
                  @click="nextSlide"  
                />
              </div>
            </div>
          </div>
          <div class="section section--no-pt section--events">
              <div class="container-l">
                  <ul class="list list--flex layout-events">
                      <li 
                        class="list-item"
                        v-for="(offer, idx) in currentPromotions"
                        :key="idx"
                        v-show="(!isShowAllOffers && idx < 5) || (isShowAllOffers)"
                      >
                        <div class="event">
                          <div class="event-header">
                            <div class="event-date">{{ getNormalFormatedDate(offer.close_date) }}</div>
                          </div>
                          <div class="event-body">
                            <div class="event-preview">
                              <img 
                                class="img_offer_small" 
                                :src="offer.linkimg" 
                                alt=""
                              >
                            </div>
                            <div class="event-title">{{ offer.name }}</div>
                            <div class="event-description" v-html="offer.subheading"></div>
                          </div>
                          <div class="event-footer">
                              <a 
                                  class="button button--primary" 
                                  @click="push('offers/' + offer.link)"
                              >
                                  Читать полностью
                              </a>
                          </div>
                        </div>
                      </li>
                  </ul>
                  <div class="load-more">
                      <a 
                          v-if="!isShowAllOffers"
                          class="button button--default" 
                          @click="isShowAllOffers = true"
                      >
                          Cмотреть еще
                      </a>
                  </div>
              </div>
          </div>
    </main>
</template>
<script>
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    components:{
        breadCrumbs,
    },
    data() {
        return {
            dataOffers: [],
            isShowAllOffers: false,
            currentSlide: 1,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            pagesOffers: 'app/pagesOffers',
            offersData:'offers/offersData',
            offers: 'app/offers',
            city: 'auth/city',
            from: 'auth/from',
            isAuthenticated:'auth/isAuthenticated',
            user:'auth/user',
            companies: 'companies/companies',
            userCompanyId: 'auth/userCompanyId',

            uiPromotions: 'ui_promotion/promotions',
        }),
        fromMayak(){
            return JSON.parse(localStorage.getItem("fromMayak"))
        },
        currentCompanyType() {
          let type = "10"
          this.companies.map((element) => {
            if (element.id == this.userCompanyId) {
              if (element.account_type) {
                type = element.account_type
              }
            }
          })
          return type
        },
        currentPromotions() {
          let array = []
          this.uiPromotions.map((element) => {
            if ((element.root_id == "0" || element.root_id == this.city) && (element.type == "0" || element.type == this.currentCompanyType)) {
              array.push(element)
            }
          })
          return array
        },
    },
    methods: {
        nextSlide() {
          if (this.currentSlide == this.currentPromotions.length) {
              this.currentSlide = 1
              return  
          }
          this.currentSlide += 1
        },
        prevSlide() {
          if (this.currentSlide == 1) {
              this.currentSlide = this.currentPromotions.length
              return  
          }
          this.currentSlide -= 1
        }
    },
}
</script>
<style scoped>
  .img_offer_small{
    max-height: 12rem;
  }
</style>