<template>
    <main class="main nd_auth">
      <div class="section section--breadcrumbs">
        <div class="container-l">
          <div class="inner">
            <ul class="list list--flex breadcrumbs">
              <li class="list-item">
                <a 
                  class="list-link" 
                  href="#"
                >
                  <svg 
                    class="icon" 
                    width="16" 
                    height="16" 
                    viewBox="0 0 16 16"
                  >
                    <use xlink:href="@/assets/img/icons.svg#home" />
                  </svg>
                </a>
              </li>
              <li class="list-item">
                <span class="list-current">Регистрация</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section section--no-pt">
            <div class="container-l">
            <div class="row row--login">
                <div class="col">
                    <div class="auth_left_block">
                        <img 
                            v-if="isPhoneExist && !isRegistrySuccesfull"
                            @click="isPhoneExist = !isPhoneExist"
                            class="auth_icon_back"
                            src="~@/img/icon_back.png" 
                            alt=""
                        >
                        <img 
                            v-if="isUserExist && !isRegistrySuccesfull"
                            @click="isUserExist = !isUserExist"
                            class="auth_icon_back"
                            src="~@/img/icon_back.png" 
                            alt=""
                        >
                        <div class="heading">
                            <div class="heading-section">
                                <p v-if="!isPhoneExist && !isUserExist && !isRegistrySuccesfull">Завершение регистрации</p>
                                <p v-if="isPhoneExist && !isRegistrySuccesfull">Номер уже используется</p>
                                <p v-if="isUserExist && !isRecoverySended && !isRegistrySuccesfull">Пользователь существует</p>
                                <p v-if="isUserExist && isRecoverySended && !isRegistrySuccesfull">Письмо отправлено</p>
                                <p v-if="isRegistrySuccesfull">Пользователь успешно создан</p>
                            </div>
                        </div>
                        <div class="subheading">
                            <p v-if="isPhoneExist" class="second_step_info mb-24">
                                Номер <span class="second_step_value">{{ formatedPhone('7' + form.phone) }}</span> уже зарегистрирован
                            </p>
                            <p v-if="isUserExist && !isRecoverySended" class="second_step_info mb-24">
                                Почта или телефон, которые вы ввели уже зарегистрированы
                            </p>

                            <p v-if="isPhoneExist" class="second_step_info">
                                Вы можете войти в существующий личный кабинет или продолжить регистрацию сменив номер телефона
                            </p>
                            <p v-if="isUserExist && !isRecoverySended" class="second_step_info">
                                Вы можете войти в существующий личный кабинет. Если вы не помните пароль на вашу почту 
                                <span class="second_step_value">{{ form.email }}</span> будет отправлено письмо со ссылкой для восстановления пароля
                            </p>
                            <p v-if="isUserExist && isRecoverySended" class="second_step_info">
                                На вашу почту <span class="second_step_value">{{ form.email }}</span> отправлено письмо со ссылкой. Перейдите по ней для смены пароля
                            </p>
                            <p v-if="isRegistrySuccesfull" class="second_step_info">
                                На <span class="second_step_value">{{ form.email }}</span> было отправлено письмо с ссылкой для подтверждения регистрации.
                                Для завершения регистрации перейдите по ссылке из полученного письма. 
                            </p>
                        </div>
                        <form 
                            class="form" 
                            onsubmit="return false;"
                        >
                            <div class="form-fieldset">
                            <div 
                                v-if="!isPhoneExist && !isUserExist && !isRegistrySuccesfull"
                                class="tabs tabs--form tabs--form--registration"
                            >
                                <div class="tabs-content">
                                <div class="tabs-item -active">
                                    <div 
                                        v-if="form.account_type == 20"
                                        class="form-item mb-24"
                                    >
                                        <div class="field">
                                            <div class="field-input">
                                                <input 
                                                    type="text" 
                                                    placeholder="Название компании" 
                                                    v-model="form.organization_name"
                                                    class="nd_auth_input"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        v-if="form.account_type == 20"
                                        class="form-item mb-24"
                                    >
                                        <div class="field">
                                            <div class="field-input">
                                                <input 
                                                    type="text" 
                                                    placeholder="Адрес компании" 
                                                    v-model="form.adress"
                                                    class="nd_auth_input"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        v-if="form.account_type == 20"
                                        class="form-item mb-24"
                                    >
                                        <div class="field">
                                            <div class="field-input">
                                                <input 
                                                    type="text" 
                                                    placeholder="ИНН" 
                                                    v-model="form.inn"
                                                    class="nd_auth_input"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        v-if="form.account_type == 20"
                                        class="form-item mb-24"
                                    >
                                        <div class="field">
                                            <div class="field-input">
                                                <input 
                                                    type="text" 
                                                    placeholder="КПП" 
                                                    v-model="form.kpp"
                                                    class="nd_auth_input"
                                                >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-item mb-24">
                                        <div class="field">
                                            <div class="field-input">
                                                <input 
                                                    type="text" 
                                                    placeholder="Имя*" 
                                                    v-model="fio"
                                                    class="nd_auth_input"
                                                    :class="validationForm.isNameEmpty ? 'error_input' : ''"
                                                >
                                                <p v-if="validationForm.isNameEmpty" class="phone_message_error">Введите имя!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-item mb-24">
                                        <div class="field registry_phone_field">
                                            <div class="field-input">
                                                <the-mask 
                                                    v-model="form.phone"
                                                    mask="+7 (###) ###-####" 
                                                    placeholder="+7" 
                                                    type="tel" 
                                                    :readonly="isCodeSended == true ? true : false"
                                                    class="nd_auth_input"
                                                    :class="((form.phone.length == 10 && form.is_check == false) || validationForm.isCodeNotConfirmed ? 'error_input' : '')"
                                                />
                                            </div>
                                            <button 
                                                v-if="!isCodeSended"
                                                class="button_nd_default" 
                                                @click="sendCode"
                                            >
                                                Получить код
                                            </button>
                                            <button 
                                                v-if="isCodeSended && !form.is_check"
                                                class="button_nd_classic" 
                                                @click="openModal"
                                            >
                                                Введите код
                                            </button>
                                            <button 
                                                v-if="form.is_check"
                                                class="button_nd_success"
                                            >
                                                <img src="~@/img/nd_icon_check.png" alt="" class="nd_icon_check">
                                                Подтвержден
                                            </button>
                                        </div>
                                        <p v-if="isCodeSended == true" class="phone_message_link" @click="changeNumber">Сменить номер</p>
                                        <p v-if="isTimerEnd == true" class="phone_message_link" @click="sendCode">Отправить код повторно</p>
                                        <p v-if="(form.phone.length == 10 && isCodeSended == false) || validationForm.isCodeNotConfirmed" class="phone_message_error">Телефон не подтвержден</p>
                                        <p v-if="isCodeSended == true && !form.is_check && smsTimer != 0" class="phone_message_error">
                                            Мы отправили код на ваш номер телефона. Получить новый код можно через {{ formatedTime(smsTimer) }}
                                        </p>
                                        <p v-if="messageError && !isErrorRedis" class="phone_message_error">{{ messageError }}</p>
                                        <p v-if="messageError && isErrorRedis" class="phone_message_error">{{ messageError }} {{ formatedTime(smsTimer) }}</p>
                                    </div>
                                    <div class="form-item mb-24">
                                        <div class="field">
                                            <div class="field-input">
                                                <input 
                                                    type="email" 
                                                    placeholder="Email*" 
                                                    v-model="form.email"
                                                    class="nd_auth_input"
                                                    autocomplete="false"
                                                    :class="validationForm.isEmailEmpty ? 'error_input' : ''"
                                                >
                                                <p v-if="validationForm.isEmailEmpty" class="phone_message_error">Введите почту!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div 
                                v-if="!isPhoneExist && !isUserExist && !isRegistrySuccesfull"
                                class="form-group"
                            >
                                <div class="form-item mb-16">
                                    <label class="checkbox-plus">
                                        <div class="checkbox_nd">
                                            <img v-if="form.account_type == 10" class="check_false" @click="form.account_type = 20" src="~@/img/checkbox_false1.png" alt="">
                                            <img v-if="form.account_type == 20" class="check_true" @click="form.account_type = 10" src="~@/img/checkbox_true.png" alt="">
                                        </div>
                                        <span>
                                            Я представитель юридического лица или ИП
                                        </span>
                                    </label>
                                </div>
                                <div class="form-item">
                                    <label class="checkbox-plus">
                                        <div class="checkbox_nd">
                                            <img v-if="isAgree == false" class="check_false" @click="isAgree = !isAgree" src="~@/img/checkbox_false1.png" alt="">
                                            <img v-if="isAgree == true" class="check_true" @click="isAgree = !isAgree" src="~@/img/checkbox_true.png" alt="">
                                        </div>
                                        
                                        <span>
                                        Согласен (согласна) на обработку моих персональных данных.
                                        Ознакомлен с 
                                        <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/BeEwJiJ_Jc.pdf" target="_blank">Политикой</a>
                                        и
                                        <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/ZhlKe-a2dH.pdf" target="_blank">согласием</a>
                                        на обработку персональных данных
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div 
                                v-if="!isPhoneExist && !isUserExist && !isRegistrySuccesfull"    
                                class="form-group"
                            >
                                <div class="form-item">
                                    <button 
                                        class="button button--primary nd-br nd_button"
                                        :disabled="!isAgree"
                                        @click="registration"  
                                    >
                                        Продолжить
                                    </button>
                                </div>
                            </div>
                            <div 
                                v-if="isPhoneExist && !isRegistrySuccesfull"    
                                class="form-group"
                            >
                                <div class="form-item">
                                    <button 
                                        class="button button--primary nd-br nd_button"
                                        @click="push('login')"  
                                    >
                                        Войти
                                    </button>
                                </div>
                            </div>
                            <div 
                                v-if="isUserExist && !isRecoverySended && !isRegistrySuccesfull"    
                                class="form-group"
                            >
                                <div class="form-item">
                                    <button 
                                        class="button button--primary nd-br nd_button"
                                        @click="recovery" 
                                    >
                                        Прислать ссылку
                                    </button>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col">
                    <div class="auth_right_block">
                        <img src="~@/img/auth_img.png" alt="">
                        <p class="title">Еще не с нами?</p>
                        <p class="subtitle">
                            После регистрации на сайте вам будет
                            доступно отслеживание состояния заказов,
                            личный кабинет и другие новые
                            возможности
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </main>
</template>
<script>
import Vue from 'vue'
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

export default {
    mixins: [Functions],
    data () {
        return {
            isAgree: false,
            isIp: false,
            fio: null,
            form: {
                account_type: 10,

                organization_name: null,
                adress: null,
                inn: null,
                kpp: null,

                surname: null,
                name: null,
                midname: null,

                email: null,
                phone: "",
                password: null,
                confirm: null,
                order_id: null,
                is_check: false,
            },
            isShowPassword: false,
            isCodeSended: false,
            smsTimer: 0,
            timerId: null,
            isPhoneExist: false,
            isEmailExist: false,
            isUserExist: false,
            isRecoverySended: false,
            isRegistrySuccesfull: false,
            isTimerEnd: false,
            messageError: null,
            isErrorRedis: false,
            validationForm: {
                isNameEmpty: false,
                isEmailEmpty: false,
                isCodeNotConfirmed: false,
            },
        }
    },
    watch: {
        isVerifyPhoneNumber(val) {
            if (val == true) {
                this.form.is_check = true
            }
        },
        isEmailExist() {
            this.isRecoverySended = false
        },
    },
    computed: {
        ...Vuex.mapGetters({
            isVerifyPhoneNumber: 'auth/isVerifyPhoneNumber',
            loginEmail: 'auth/loginEmail',
            loginPhone: 'auth/loginPhone',
        }),
    },
    mounted() {
        this.checkStorageOrderId()
        this.checkStoreValues()
    },
    methods: {
        ...Vuex.mapMutations({
            setSuccessRegistryEmail: 'app/setSuccessRegistryEmail',
            setShowSuccessRegistry: 'app/setShowSuccessRegistry',
            setShowUserExistModal: 'app/setShowUserExistModal',
            setShowSendCodeSmsModal: 'app/setShowSendCodeSmsModal',
            setRegistryModalPhone: 'app/setRegistryModalPhone',
        }),
        ...Vuex.mapActions({
            registry: 'auth/registry',
            verify: 'auth/verify',
            recoveryStore: 'auth/recovery',
            sendsms: 'auth/sendsms',
        }),
        checkStoreValues() {
            if (this.loginEmail != null) {
                this.form.email = this.loginEmail
            }
            if (this.loginPhone != null) {
                this.form.phone = this.loginPhone
            }
        },
        validation() {
            var is_can = true
            if (this.form.email == null || this.form.email.length == 0) {
                this.validationForm.isEmailEmpty = true
                is_can = false
            }else{
                this.validationForm.isEmailEmpty = false
            }
            if (this.fio == null || this.fio.length == 0) {
                this.validationForm.isNameEmpty = true
                is_can = false
            }else{
                this.validationForm.isNameEmpty = false
            }
            if (this.form.is_check == false) {
                this.validationForm.isCodeNotConfirmed = true
                is_can = false
            }else{
                this.validationForm.isCodeNotConfirmed = false
            }
            return is_can
        },
        checkStorageOrderId() {
            if (localStorage.mayakOrderId) {
                this.form.order_id = JSON.parse(localStorage.getItem("mayakOrderId"))
            }
        },
        sendCode() {
            if (this.form.phone.length == 0) {
                return
            }
            this.sendsms({phone: '+7' + this.form.phone})
                .then(() => {
                    this.isCodeSended = true
                    clearTimeout(this.timerId)
                    this.isTimerEnd = false
                    this.startTimer()
                    this.messageError = null
                })
                .catch((err) => {
                    this.messageError = 'Вы уже отправили код на этот телефон, повторно можно будет отправить через'
                    this.isErrorRedis = true
                    var splitedError = err.response.data.error.split(',')
                    var responseTime = splitedError[1]
                    clearTimeout(this.timerId)
                    this.isTimerEnd = false
                    this.startTimer(Number(responseTime)) 
                })
        },
        openModal() {
            if (this.form.phone.length == 0) {
                return
            }
            this.setRegistryModalPhone('7' + this.form.phone)
            this.setShowSendCodeSmsModal(true)
        },
        registration() {
            if (!this.validation()) {
                return
            }
            if (this.fio) {
                let splited = this.fio.split(' ')

                if (splited[0]) {
                    this.form.surname = splited[0]
                }
                if (splited[1]) {
                    this.form.name = splited[1]
                }
                if (splited[2]) {
                    this.form.midname = splited[2]
                }
              }
  
            this.registry(this.form)
                .then(() => {
                this.isRegistrySuccesfull = true
                })
                .catch((error) => {
                if (error.response.status == 409) {
                    if (error.response.data.error == 'phone exists') {
                        this.isPhoneExist = true
                    }else if (error.response.data.error == 'email exists') {
                        this.isEmailExist = true
                    }else if (error.response.data.error == 'user exists') {
                        this.isUserExist = true
                    }
                }
                })
          },
        startTimer(time) {
            if (!time) {
                time = 300
            }
            this.smsTimer = time
            if (time && time == 1) {
                this.smsTimer = 0
                this.isErrorRedis = false
                this.isTimerEnd = true
                return
            }
            this.timerId = setTimeout(function(){
                this.startTimer(time - 1)
            }.bind(this), 1000)
        },
        formatedTime(s) {
            let m = Math.trunc(s / 60) + ''
            s = (s % 60) + ''
            return m.padStart(2, 0) + ':' + s.padStart(2, 0)
        },
        recovery() {
            this.recoveryStore({email: this.form.email})
                .then(() => {
                    this.isRecoverySended = true 
                })
        },
        changeNumber() {
            this.form.phone = ''
            this.isCodeSended = false
            this.form.is_check = false
        }
      },
  }
  </script>