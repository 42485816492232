<template>
  <main class="main">
    <div class="section section--breadcrumbs">
      <div class="container-l">
        <div class="inner">
          <ul class="list list--flex breadcrumbs">
            <li class="list-item">
              <a class="list-link" href="#">
                <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                  <use xlink:href="@/assets/img/icons.svg#home"></use>
                </svg>
              </a>
            </li>
            <li class="list-item">
              <span class="list-current">Поиск по каталогу</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
        <div class="section section--no-pt" id="topCatalog">
          <div class="container-l">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Поиск по каталогу</h2>
                </div>
              </div>
              <h3 v-if="productsPage.length == 0">По  запросу «{{ queryPage }}» ничего не найдено</h3>
              <h3 v-else>Результат поиска по запросу «{{ queryPage }}»</h3>
              <div v-if="productsPage.length != 0" class="catalog-controls">
                <div class="catalog-controls-inner">
                  <!-- <div class="catalog-control">
                    <div class="catalog-control-label">Сортировать по:</div>
                    <div class="catalog-control-values">
                      <choices-select 
                        data-select 
                        :elements="arraySort" 
                        v-model="form.sort"
                        :defaultValue="'Не выбрано'" 
                        :typeSelect="'float'" 
                      />
                    </div>
                  </div> -->
                  <div class="catalog-control">
                    <div class="catalog-control-label">Показывать по:</div>
                    <div class="catalog-control-values">
                      <choices-select 
                        data-select 
                        :elements="arrayLimits" 
                        v-model="form.limit"
                        @change="update"
                        :defaultValue="form.limit" 
                        :typeSelect="'float'" 
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul class="list list--flex layout-products -col-4">
              <single-card v-for="product in productsPage" :key="product.id" :product="product" v-show="product.price != 0" />
            </ul>
            <v-pagination
              v-if="productsPage.length != 0"
              v-model="currentPage"
              :page-count="amountPages"
              :classes="bootstrapPaginationClasses"
            ></v-pagination>
          </div>
        </div>
  </main>
</template>
<script>
import Vuex from 'vuex'
import choicesSelect from '@/components/choices-select/choices-select.vue'
import vPagination from 'vue-plain-pagination'
import singleCard from '@/components/pages/catalog/single-card.vue'

export default {
    components: {
        choicesSelect,
        vPagination,
        singleCard,
    },
    data() {
        return {
            arraySort: [
                {id: "10", name: 'Названию (А -> Я)'},
                {id: "20", name: 'Названию (Я -> А)'},
            ],
            arrayLimits: [
                {id: 1, name: '12'},
                {id: 2, name: '24'},
                {id: 3, name: '36'},
            ],
            currentPage: 1,

            bootstrapPaginationClasses: {
              ul: 'list list--flex pagination',
              li: 'list-item',
              liActive: '-active',
              liDisable: 'disabled',
              button: 'list-link'
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            searchForm: 'search/form',
            queryPage: 'search/queryPage',
            productsPage: 'search/productsPage',
            amountPages: 'search/amountPages',
            yandexCloud: 'app/yandexCloud',
        }),
        form() {
            return Object.assign({}, this.searchForm)
        },
    },
    watch: {
      currentPage(val) {
        this.setPage(val)
      },
    },
    methods: {
      ...Vuex.mapMutations({
        updateForm: 'search/updateForm',
      }),
      ...Vuex.mapActions({
        searchPage: 'search/searchPage',
      }),
      setPage(page) {
        let topElement = document.querySelector('#topCatalog')

        window.scrollTo(0, topElement.offsetTop)
        this.form.page = page
        this.updateForm(this.form)
        this.searchPage()
      },
      update() {
        let value = null
        this.arrayLimits.map(element => {
          if (element.id == Number(this.form.limit)) {
            value = Number(element.name)
          }
        })

        this.form.limit = value

        this.updateForm(this.form)
        this.searchPage()
      }
    },
}
</script>