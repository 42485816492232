<template>
    <div class="singleAddress">
        <span>Баннер №{{ index+1 }}</span>
        <div
            class="region index__admin" 
        >
            <select 
                v-model="editForm.root_id" 
                @change="update"
            >
                <option value="">Выберите регион</option>
                <option 
                    v-for="(category, idx) in topLevelCategory"
                    :key="idx"
                    :value="category.id_1c"
                >
                    {{ category.name }}
                </option>
            </select>
        </div>
        <img v-if="editForm.link_image != null" :src="editForm.link_image" alt="" class="coating_main_img">
        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'contactsUploadBannerSingle' + editForm.id" 
                    type="file" 
                    @change="uploadImg"
                >
            </label>
            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
        </div>
        <div class="buttons">
            <button 
                class="delete_button"
                @click="deleteBanner()"
            >
                Удалить
            </button>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
        editForm() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            deleteContactBanner: 'ui_contacts_banner/deleteContactBanner',
            updateContactBanner: 'ui_contacts_banner/updateContactBanner',
            upload: 'app/upload',
        }),
        deleteBanner() {
            this.deleteContactBanner(this.element.id)
        },
        update() {
            this.updateContactBanner(this.editForm)
        },
        uploadImg() {
            let image = this.$refs['contactsUploadBannerSingle' + this.element.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editForm.link_image = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
    }
}
</script>