<template>
     <main class="main">
        <bread-crumbs :pages-array="pagesBasket" :withNdProp="false" />
        <div class="section section--no-pt">
          <div class="container-l">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Оформление заказа</h2>
                </div>
              </div>
            </div>
            <div class="layout layout--checkout" style="justify-content: space-between;">
              <div class="layout-bside">
                <div class="checkout-menu">
                  <div class="checkout-menu-toggle">
                    <div class="checkout-menu-toggle-section"><span>Информация о заказе</span><span>Свернуть</span></div>
                    <button class="checkout-menu-toggle-button"></button>
                  </div>
                  <div class="result checkout-result">
                    <div class="result-header">
                      <div class="result-title">
                        Ваш заказ 
                        <a class="edit" @click="push('basket')">
                          <svg class="icon" width="15.966" height="16" viewBox="0 0 15.966 16">
                            <use xlink:href="@/assets/img/icons.svg#edit"></use>
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div class="result-body">
                      <div class="result-group">
                        <div class="result-label">Товаров на сумму</div>
                        <div class="result-value">{{ fullSum }} {{ regionCurrency[regionCode].currency }}</div>
                      </div>
                      <!-- <div class="result-group">
                        <div class="result-label">Доставка на сумму</div>
                        <div class="result-value">0 {{ regionCurrency[regionCode].currency }}</div>
                      </div> -->
                    </div>
                    <div class="result-footer">
                      <div class="result-group">
                        <div class="result-label">Итого на сумму</div>
                        <div class="result-value">{{ fullSum }} {{ regionCurrency[regionCode].currency }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              

              <div class="layout_two_sections" style="padding-left: 31px; width: 75%;">
                <delivery-tab />
                <buyer-tab />
              </div>
            </div>
          </div>
        </div>
      </main>
</template>

<script>
import Vuex from 'vuex'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Functions from '@/utils/functions'

// import regionTab from './tabs/region-tab.vue'
import deliveryTab from './tabs/delivery-tab.vue'
// import payTab from './tabs/pay-tab.vue'
import buyerTab from './tabs/buyer-tab.vue'
// import lastStep from './last-step.vue'

export default {
  mixins: [Functions],
  components: {
      breadCrumbs,
      // regionTab,
      deliveryTab,
      // payTab,
      buyerTab,
      // lastStep,
  },
  computed: {
    ...Vuex.mapGetters({
        pagesBasket: 'dictionary/pagesBasket',
        order: 'basket/order',
        user: 'auth/user',
        isOrderReady: 'basket/isOrderReady',
        regionCode: 'auth/regionCode',
        regionCurrency: 'dictionary/regionCurrency',
    }),
    editOrder() {
      return Object.assign({}, this.order)
    },
    key() {
      return this.$route.params.key
    },
    fullSum() {
      let sum = 0
      
      if (this.order.positions.length != 0) {
        this.order.positions.map((element) => {
          sum += element.sell
        })
      }

      return this.getTrueBigSum(sum)
    },
  },
  watch: {
    isOrderReady() {
      this.setValues()
    }
  },
  mounted() {
    this.setValues()
    this.checkPositions()
  },
  methods: {
    ...Vuex.mapMutations({
       changeOrder: 'basket/changeOrder',
    }),
    checkPositions() {
        if (this.order.positions.length != 0) {
          return
        }
        this.push('basket')
    },
    setValues() {
      if (this.user.account_type) {
        this.editOrder.account_type = this.user.account_type
      }else{
        this.editOrder.account_type = 30
      }
      this.editOrder.delivery_type = 10
      this.editOrder.pay_type = 20

      if (this.user.organization_name) {
        this.editOrder.organization_name = this.user.organization_name
      }
      if (this.user.adress) {
        this.editOrder.organization_address = this.user.adress
      }
      if (this.user.inn) {
        this.editOrder.inn = this.user.inn
      }
      if (this.user.kpp) {
        this.editOrder.kpp = this.user.kpp
      }
      this.editOrder.fio = this.getFullName(this.user.surname, this.user.name, this.user.midname)
      if (this.user.email) {
        this.editOrder.email = this.user.email
      }
      if (this.user.phone) {
        this.editOrder.phone = this.user.phone
      }
      this.changeOrder(this.editOrder)
    },
    getFullName(surname, name, midname) {
      let full = ''
      if (surname) {
        full += surname + ' '
      }
      if (name) {
        full += name + ' '
      }
      if (midname) {
        full += midname
      }
      return full
    },
  }
}
</script>