<template>
  <main class="main">
        <div class="section section--breadcrumbs">
          <div class="container-l">
            <div class="inner">
              <ul class="list list--flex breadcrumbs">
                <li class="list-item"><a class="list-link" href="#">
                    <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                      <use xlink:href="@/assets/img/icons.svg#home"></use>
                    </svg></a></li>
                <li class="list-item"><a class="list-link" href="#">Корзина</a></li>
                <li class="list-item"><span class="list-current">Оформление заказа</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section section--no-pt">
          <div class="container-l">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Заказ сформирован</h2>
                </div>
              </div>
            </div>
            <div class="row row--checkout-finish">
              <div class="col"><img src="@/assets/img/checkout-finish.svg" alt="">
                <p>Вы можете следить за выполнением своего заказа в личном кабинете сайта. Обратите внимание, что для входа в этот раздел вам необходимо будет ввести логин и пароль пользователя сайта.</p>
              </div>
              <div class="col">
                <div class="group">
                  <div class="group-header">
                    <div class="group-title">Оплата</div>
                  </div>
                  <div class="group-body">
                    <div class="row row--checkout-info">
                      <div class="col">
                        <div class="label">Тип оплаты</div>
                        <p>{{ getNameById(payTypes, finishedOrder.pay_type) }}</p>
                      </div>
                      <div class="col" v-if="finishedOrder.pay_type != 20">
                        <div class="row row--payment-cards">
                          <div class="col"><img src="@/assets/img/payment-visa.svg" alt=""></div>
                          <div class="col"><img src="@/assets/img/payment-mastercard.svg" alt=""></div>
                          <div class="col"><img src="@/assets/img/payment-paykeeper.svg" alt=""></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="group-body">
                    <div class="row row--checkout-info">
                      <div class="col">
                        <div class="checkout-total-price">
                          <div class="checkout-total-price-label">Стоимость</div>
                          <div class="checkout-total-price-value"><span>{{ getTrueBigSum(finishedOrder.sum) }}</span> {{ regionCurrency[regionCode].currency }}</div>
                        </div>
                      </div>
                      <div class="col">
                        <p class="mb-0">
                          Ваш заказ успешно сформирован! 
                        </p>
                        <p class="mb-0">
                          Наш специалист свяжется с Вами в ближайшее время для подтверждения заказа!
                        </p>
                        <p class="mb-0">
                          Если у Вас возникнут вопросы, пожалуйста, свяжитесь с нами <a href="info@mayakmetall.ru">info@mayakmetall.ru</a>!
                        </p>
                        <p class="mb-0">   
                          Спасибо за обращение!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            payTypes: 'dictionary/payTypes',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            finishedOrder: 'basket/finishedOrder',
        }),
    }
}
</script>
<style lang="scss">
    .mb-0{
      margin-bottom: 0 !important;
    }
</style>