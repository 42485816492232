<template>
  <div>
    <div class="section section--breadcrumbs">
      <div  
        :class="[
          withNdProp ? 'container-nd' : '',
          woContainer ? '' : 'container-l',
        ]"
      >
        <div class="inner inner-nd">
          <ul class="list list--flex breadcrumbs">
            <li class="list-item">
              <a 
                class="list-link" 
                @click="push('')"
              >
                <svg 
                  class="icon" 
                  width="16" 
                  height="16" 
                  viewBox="0 0 16 16"
                >
                  <use xlink:href="@/assets/img/icons.svg#home" />
                </svg>
              </a>
            </li>
            <li 
              v-for="(page, idx) in listPages"
              :key="idx"
              class="list-item list-link" 
              @click="push(page.fullUrl)"
            >
              <span :class="(idx == listPages.length - 1 ? 'list-current' : '')">
                {{ page.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    props: {
      pagesArray: {
        type: Array,
        default: () => []
      },
      withNdProp: {
        type: Boolean,
        default: true
      },
      woContainer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
        return{
            listPages: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            pages: 'dictionary/pages',
        }),
        path() {
            return this.$route.path
        },
    },
    watch: {
        path() {
            this.parseUrl()
        }
    },
    mounted() {
        this.parseUrl()
    },
    methods: {
        parseUrl() {
            let value
            this.listPages = []
            if (this.path) {
                value = this.path
                value = value.split('/')
                value.map(object => {
                    this.pagesArray.map(element => {
                        if (element.url == object) {
                            this.listPages.push({name: element.name, fullUrl: element.fullUrl})
                        }
                    })
                })
            }
        }
    },
}
</script>