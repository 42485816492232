import Http from "../../utils/http"
import Config from "../../config"

const state = {
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    products: [],
}

const getters = {
    form: state => state.form,
    products: state => state.products,
}

const actions = {
    getProducts: ({commit}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_popular_products/search", {})
                .then(response => {
                    commit("setProducts", response.data)
                    resolve(response)
                })
        })
    },
    createProduct: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_popular_products/admin", payload)
                .then(response => {
                    commit("pushProduct", response.data)
                    resolve(response)
                })
        })
    },
    deleteProduct: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_popular_products/admin/" + id)
                .then(response => {
                    commit("spliceProduct", id)
                    resolve(response)
                })
        })
    },
    updateProduct: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_popular_products/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeProduct", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setProducts: (state, val) => {
        val.sort((a, b) => Number(a.id) - Number(b.id))
        state.products = val
    },
    spliceProduct: (state, id) => {
        state.products.map((element, idx) => {
            if (id == element.id) {
                state.products.splice(idx, 1)
            }
        })
    },
    changeProduct: (state, val) => {
        state.products.map((element) => {
            if (element.id == val.id) {
                element.id_1c = val.id_1c
                element.sort = val.sort
                element.root_id = val.root_id
            }
        })
    },
    pushProduct: (state, val) => {
        state.products.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
