<template>
    <div class="admin-index-goods">
        <h4>Популярные товары</h4>

        <button
            v-if="!isShowCreateForm"
            @click="isShowCreateForm = true"
        >
            Добавить товар
        </button>

        <div v-if="isShowCreateForm" class="goods-create-form">
            <label for="">Id 1c товара</label>
            <input type="text" v-model="createForm.id_1c">

            <button @click="closeForm">Отменить</button>
            <button @click="create">Добавить</button>
        </div>

        <single-product 
            v-for="(product, idx) in products" 
            :key="idx"
            :element="product" 
            :index="idx"
        />
    </div>
</template>
<script>
import Vuex from 'vuex'
import singleProduct from './good.vue'

export default {
    components: {
        singleProduct,
    },
    data() {
        return {
            createForm: {
                id_1c: null,
                sort: null,
                root_id: null,
            },
            isShowCreateForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            products: 'ui_products/products',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            createProduct: 'ui_products/createProduct',
        }),
        closeForm() {
            this.isShowCreateForm = false
            this.createForm.id_1c = null
            this.createForm.sort = null
            this.createForm.root_id = null
        },
        create() {
            this.createProduct(this.createForm)
                .then(() => {
                    this.closeForm()
                })
        },
    },
}
</script>