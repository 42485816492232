<template>
  <div class="container-fluid mt-5">
    <bread-crumbs :pages-array="pagesDocuments" :withNdProp="false" />
    <div>
      <div class="section section--no-pt">
          <div class="container-l">
                <div class="inner">
                  <div class="heading">
                    <div class="heading-section">
                      <h2>Документы</h2>
                    </div>
                  </div>
                </div>
            <div class="layout layout--page">
              <div class="layout-aside">
                <ul class="list navigation-aside">
                  <li 
                    class="list-item"
                    :class="(currentCategory == 0 ? '-active' : '')"
                    @click="push('documents')"
                  >
                    <a class="list-link">Все документы</a>
                  </li>
                  <li 
                    v-for="category in categories" 
                    :key="category.id"
                    class="list-item" 
                    :class="(currentCategory == category.id ? '-active' : '')"
                    @click="push('documents/' + category.url)"
                  >
                    <a class="list-link">{{ category.name }}</a>
                  </li>
                </ul>
              </div>
              <div class="layout-section">
                <ul class="list list--flex layout-cards -col-3">
                  <li 
                    v-for="(file, i) in uiDocuments" 
                    v-show="(file.category == currentCategory && currentCategory != 0) || currentCategory == 0" 
                    :key="i"
                    class="list-item"
                  >
                    <a 
                      class="card" 
                      @click="openPdfPreviewModal(file.link)"
                      target="_blank"
                    >
                      <div class="card-heading document-card-title">
                        <div class="card-title">{{ file.name }}</div>
                      </div>
                      <div class="document-preview new-preview-width">
                        <img :src="file.img" alt="" class="preview_img" style="max-height: 222px">
                      </div>
                      <div class="card-arrow">
                        <svg 
                          class="icon" 
                          width="19" 
                          height="19" 
                          viewBox="0 0 19 19"
                        >
                          <use xlink:href="@/assets/img/icons.svg#arrow-top-right" />
                        </svg>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'

export default {
    components: {
      breadCrumbs,
    },
    mixins: [Functions],
    data() {
        return {
            
        }
    },
    computed: {
        ...Vuex.mapGetters({
            documents: 'app/documents',
            categories: 'dictionary/documentsCats',
            pagesDocuments: 'dictionary/pagesDocuments',
            uiDocuments: 'ui_document/documents',
            yandexCloud: 'app/yandexCloud',
        }),
        currentCategory() {
          let num = 0
          if (this.$route.params.key) {
            this.categories.map(element => {
              if (element.url == this.$route.params.key) {
                num = element.id
              }
            })
          }
          return num
        }
    },
    methods: {
      ...Vuex.mapMutations({
        setPdfPreviewModal: 'app/setPdfPreviewModal',
        setPdfPreviewModalLink: 'app/setPdfPreviewModalLink',
      }),
      openPdfPreviewModal(link) {
        // this.setPdfPreviewModal(true)
        // this.setPdfPreviewModalLink(link)
        window.open(link, '_blank')
      },
    },
}
</script>
<style lang="scss">
  .document-preview{
    position: absolute;
    top: 30px;
    left: 30%;
    z-index: 2;

    img{
      width: 55%;
    }
  }

  .document-card-title{
    bottom: 10px !important;
    z-index: 5 !important;
  }

  .new-preview-width{
    img{
      width: 80%;
    }
  }

  @media (min-width: 1700px) {
    .new-preview-width{
      img{
        width: 100%;
      }
    }
  }
  
</style>