<template>
    <main class="admin-index index__admin">
        <div class="admin-index_header">
            <h3>Главная</h3>
            <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button>
        </div>

        <ui-slides />

        <ui-categories />
    
        <div class="admin-index-about">
            <h4 class="header">О компании</h4>
            <input 
                type="text" 
                placeholder="Заголовок" 
                v-model="aboutHeader" 
                @keyup="updateAbout"
            >
            <vue-editor 
                v-model="aboutDescription" 
                @text-change="updateAbout" 
                class="editor"
            />
        </div>
        <div class="admin-index-subdivisions">
            <h4>Подразделения и производственные мощности на карте</h4>
            <div class="map" data-map>
                <div class="map-area">
                    <img src="@/assets/img/map.svg" alt="" @click="clickMap" v-click-outside="onClickOutside" id="map_block">
                    <div class="map-points" @click="clickMap" id="map_points">
                        <div 
                            v-for="(point, i) in main.map_points"
                            :key="i"
                            class="map-point" 
                            :class="(point.type == 10 ? 'map-point--red' : 'map-point--blue')" 
                            :style="getPointStyle(point)"
                            :data-map-point="(point.type == 10 ? 'subdivision' : 'manufacture')"
                        />
                        <div class="map-point" v-if="newPoint.left != 0 && newPoint.top != 0" :class="(newPoint.type == 10 ? 'map-point--red' : 'map-point--blue')" :style="getPointStyle(newPoint)" data-map-point="manufacture"></div>
                    </div>
                </div>
            </div>
            <div class="subdivisions-block">
                <div class="subdivisions">
                    <p class="header_block">
                        Подразделения
                    </p>
                    <div class="addform">
                        <input type="text" v-model="nameSubdivision" @keyup="updatePoint(10)" id="input_add_subdivision">
                        <button class="button" id="button_add_subdivision" :class="(newPoint.left != 0 && newPoint.top != 0 && nameSubdivision != null ? 'button--primary' : '')" @click="addPoint(10)">
                            Добавить
                        </button>
                    </div>
                    <div class="subdivisions-list">
                        <ul class="list">
                            <li v-for="(subd, i) in main.map_points" :key="i" v-show="subd.type == 10">
                                <p>{{ subd.name }}</p>
                                <i class="fas fa-times" @click="deleteSubdivision(i)"></i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="productions">
                    <p class="header_block">
                        Производства
                    </p>
                    <div class="addform">
                        <input type="text" v-model="nameManufacture" @keyup="updatePoint(20)" id="input_add_manufacture">
                        <button class="button" id="button_add_manufacture" :class="(newPoint.left != 0 && newPoint.top != 0 && nameManufacture != null ? 'button--primary' : '')" @click="addPoint(20)">
                            Добавить
                        </button>
                    </div>
                    <div class="subdivisions-list">
                        <ul class="list">
                            <li v-for="(subd, i) in main.map_points" :key="i" v-show="subd.type == 20">
                                <p>{{ subd.name }}</p>  
                                <i class="fas fa-times" @click="deleteSubdivision(i)"></i>  
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <ui-clients />

        <ui-products />

        <ui-networks />

        <div class="admin-index-goods">
            <h4>Блок Интернет магазин</h4>

            <input type="text" placeholder="Заголовок" v-model="blockInternetStore.header" @keyup="updateStore">
            <vue-editor 
                v-model="blockInternetStore.description"
                @text-change="updateStore" 
                class="editor"
            />
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import { VueEditor } from "vue2-editor"
import Functions from '@/utils/functions'
import vClickOutside from 'v-click-outside'

import uiCategories from './ui_categories.vue'
import uiClients from './ui_clients.vue'
import uiNetworks from './ui_networks.vue'
import uiSlides from './ui_slides.vue'
import uiProducts from './ui_popular.vue'

export default {
    components: {
        VueEditor,
        uiCategories,
        uiClients,
        uiNetworks,
        uiSlides,
        uiProducts,
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    mixins: [Functions],
    data() {
        return {
            aboutHeader: null,
            aboutDescription: null,
            nameSubdivision: null,
            nameManufacture: null,
            newPoint: {
              left: 0,
              top: 0,
              type: 0,
              name: null
            },
            social: {
              vk: null,
              facebook: null,
              twitter: null,
            },
            blockInternetStore: {
                header: null,
                description: null
            }
        }
    },
    computed: {
        ...Vuex.mapGetters({
            main: 'app/main',
            uiCategories: 'ui_category/categories',
        }),
    },
    watch: {
        main: {
            handler: function () {
                this.aboutHeader = this.main.aboutCompany.header
                this.aboutDescription = this.main.aboutCompany.description

                this.social.vk = this.main.social.vk
                this.social.facebook = this.main.social.facebook
                this.social.twitter = this.main.social.twitter

                this.blockInternetStore.header = this.main.blockInternetStore.header
                this.blockInternetStore.description = this.main.blockInternetStore.description
            },
            deep: true
        }
    },
    mounted() {
      if (this.main.aboutCompany) {
        this.aboutHeader = this.main.aboutCompany.header
        this.aboutDescription = this.main.aboutCompany.description
      }
      if (this.main.social) {
        this.social.vk = this.main.social.vk
        this.social.facebook = this.main.social.facebook
        this.social.twitter = this.main.social.twitter
      }
      if (this.main.blockInternetStore) {
        this.blockInternetStore.header = this.main.blockInternetStore.header
        this.blockInternetStore.description = this.main.blockInternetStore.description
      }
      this.getPopularProducts()
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            getPopularProducts: 'ui_products/getProducts',
        }),
        ...Vuex.mapMutations({
            pushMainSlide: 'app/pushMainSlide',
            updateMainAboutCompany: 'app/updateMainAboutCompany',
            pushMainPopularProduct: 'app/pushMainPopularProduct',
            pushMainClient: 'app/pushMainClient',
            pushMainMapPoint: 'app/pushMainMapPoint',
            pushMainCatalogCategory: 'app/pushMainCatalogCategory',
            deleteSubdivision: 'app/deleteSubdivision',
            updateMainSocial: 'app/updateMainSocial',
            updateMainStore: 'app/updateMainStore',
        }),
        updateStore() {
            this.updateMainStore(this.blockInternetStore)
        },
        updateAbout() {
            this.updateMainAboutCompany({header: this.aboutHeader, description: this.aboutDescription})
        },
        updateData() {
            this.updateJson()
        },
        clickMap(event) {
          if (event.target.className != 'map-points') {
            return
          }
          let rect = event.target.getBoundingClientRect()
          this.newPoint.left = 100 * event.offsetX / (rect.width + 10)
          this.newPoint.top = 100 * event.offsetY / (rect.height + 10)
        },
        updatePoint(type) {
          if (type == 10) {
            this.newPoint.type = 10
            this.newPoint.name = this.nameSubdivision
          }else if (type == 20) {
            this.newPoint.type = 20
            this.newPoint.name = this.nameManufacture
          }
        },
        addPoint(type) {
          this.newPoint.type = type
          if (this.newPoint.left == 0 || this.newPoint.top == 0) {
            return
          }
          if ((this.newPoint.type == 10 && this.nameSubdivision == null) || (this.newPoint.type == 20 && this.nameManufacture == null)) {
            return
          }
          
          if (type == 10) {
            this.newPoint.name = this.nameSubdivision
            this.nameSubdivision = null
          }else if (type == 20) {
            this.newPoint.name = this.nameManufacture
            this.nameManufacture = null
          }
          
          this.pushMainMapPoint({
            left: this.newPoint.left,
            top: this.newPoint.top,
            type: this.newPoint.type,
            name: this.newPoint.name
          })
          this.clearForm()
        },
        clearForm() {
          this.newPoint.left = 0
          this.newPoint.top = 0
          this.newPoint.type = 0
          this.newPoint.name = null
        },
        getPointStyle(element) {
          let response = '' 

          response += 'left: ' + element.left + '%;'
          response += 'top: ' + element.top + '%;'

          return response
        },
        onClickOutside(ev) {
          if (ev.target.id == 'map_block' || ev.target.id == 'map_points' || ev.target.id == 'input_add_subdivision' || ev.target.id == 'button_add_subdivision' || ev.target.id == 'input_add_manufacture' || ev.target.id == 'button_add_manufacture') {
            return
          }
          this.newPoint = {
            left: 0,
            top: 0,
            type: 0,
            name: null
          }
        },
        updateSocial(type) {
          this.updateMainSocial({type: type, value: this.social[type]})
        }
    },
}
</script>
<style lang="scss">
    .admin-index{
        width: 82%;
        padding: 20px;
        overflow: auto;
        max-height: 91vh;

        .admin-index_header{
            position: relative;

            .save_btn{
                position: fixed;
                top: 70px;
                right: 20px;
                z-index: 100;
            }
        }

        .admin-index-slider{
            .slides{
                .slide{
                    display: grid;
                    position: relative;
                    margin-bottom: 30px;

                    .header{
                      margin-bottom: 10px;
                    }

                    img{
                      width: 50%;
                    }
                }
            }
        }
        
        .admin-index-catalog{
          margin-top: 50px;
          margin-bottom: 50px;

          h4{
            margin-bottom: 10px;
          }

          .single-catalog{
            display: grid;
            margin-bottom: 10px;

            .header{
              margin-bottom: 10px;
            }
          }
        }

        .admin-index-about{
          display: grid;

          .header{
            margin-bottom: 20px;
          }
          .editor{
            margin-top: 20px;
            margin-bottom: 100px;
          }
        }

        .admin-index-subdivisions{
            .map{
              width: 1000px;
              
              .map-area{
                max-width: 90%;
              }
            }

            .subdivisions-block{
                display: flex;

                .subdivisions{
                    width: 380px;
                    margin-right: 50px;

                    .header_block{
                        font-size: 15px;
                        font-weight: 600;
                        margin-bottom: 15px;
                    }

                    .addform{
                        display: flex;

                        input{
                            margin-right: 10px;
                        }

                        .button{
                            padding-top: 8px;
                            padding-bottom: 10px;
                        }
                    }

                    .subdivisions-list{

                        .list{
                            p{
                                padding: 10px;
                                padding-left: 20px;
                                font-size: 15px;
                                margin-bottom: 0;
                            }

                            li{
                              display: flex;
                              width: 100%;
                              justify-content: space-between;
                              padding-right: 20px;

                              i{
                                font-size: 13px;
                                margin-top: 15px;
                                float: right;

                                &:hover{
                                  cursor: pointer;
                                }
                              }
                            }
                        }
                    }
                }

                .productions{
                    width: 380px;

                    .header_block{
                        font-size: 15px;
                        font-weight: 600;
                        margin-bottom: 15px;
                    }

                    .addform{
                        display: flex;

                        input{
                            margin-right: 10px;
                        }

                        .button{
                            padding-top: 8px;
                            padding-bottom: 10px;
                        }
                    }

                    .subdivisions-list{

                        .list{
                            p{
                                padding: 10px;
                                padding-left: 20px;
                                font-size: 15px;
                                margin-bottom: 0;
                            }

                            li{
                              display: flex;
                              width: 100%;
                              justify-content: space-between;
                              padding-right: 20px;

                              i{
                                font-size: 13px;
                                margin-top: 15px;
                                float: right;

                                &:hover{
                                  cursor: pointer;
                                }
                              }
                            }
                        }
                    }
                }
            }
        }

        .admin-index-clients{
            margin-top: 50px;

            h4{
                margin-bottom: 10px;
            }
        }

        .admin-index-goods{
            margin-top: 50px;

            h4{
                margin-bottom: 10px;
            }
        }

        .admin-index-shares{
            margin-top: 50px;

            h4{
                margin-bottom: 10px;
            }
        }
    }
</style>