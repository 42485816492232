<template>
    <main class="admin-index index__admin" style="display: block;">
        <div class="admin-index_header">
            <h3>О компании</h3>
        </div>
        <div class="index__admin">
            <h4>Новости</h4>
            <button v-if="!isShowCreateForm" @click="isShowCreateForm = true">
                Добавить новость
            </button>

            <div v-if="isShowCreateForm" class="create_form_block">
                <label for="">Название</label>
                <input type="text" v-model="createForm.name">
    
                <label for="">Описание</label>
                <vue-editor 
                    v-model="createForm.description"
                />
    
                <label for="">Дата</label>
                <input type="date" v-model="createForm.data">

                <label for="">Ссылка на страницу</label>
                <input type="text" v-model="createForm.link">
    
                <label for="">Изображение</label>
                <input type="text" v-model="createForm.image">
                <img v-if="createForm.image != null" :src="createForm.image" alt="" class="coating_main_img">
                <div class="row">
                    <label class="fl__img">
                        <input 
                            :ref="'acnewsUpload'" 
                            type="file" 
                            @change="uploadImg"
                        >
                    </label>
                    <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                </div>
    
                <label for="">Регион</label>
                <select 
                    v-model="createForm.root_id" 
                >
                    <option value="">Выберите регион</option>
                    <option 
                        v-for="(category, idx) in topLevelCategory"
                        :key="idx"
                        :value="category.id_1c"
                    >
                        {{ category.name }}
                    </option>
                </select>

                <div class="buttons_block">
                    <button @click="closeForm">
                        Отменить
                    </button>
                    <button @click="create">
                        Добавить
                    </button>
                </div>
            </div>

            <div v-if="!isShowCreateForm">
                <single-news
                    v-for="singleNews, idx in news"
                    :key="singleNews.id"
                    :index="idx"
                    :element="singleNews"
                />
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import { VueEditor } from "vue2-editor"
import singleNews from './singleNew.vue'

export default {
    components: {
        VueEditor,
        singleNews,
    },
    // mixins: [Functions],
    data() {
        return {
            createForm: {
                data: null,
                description: null,
                image: null,
                name: null,
                root_id: '',
                link: null,
            },
            isShowCreateForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            news: 'ui_news/news',
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
    },
    mounted() {
        this.getNews({})
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            getNews: 'ui_news/getNews',
            createNews: 'ui_news/createNews',
        }),
        closeForm() {
            this.createForm.data = null
            this.createForm.description = null
            this.createForm.image = null
            this.createForm.name = null
            this.createForm.root_id = ''
            this.createForm.link = null
            this.isShowCreateForm = false
        },
        uploadImg() {
            let image = this.$refs['acnewsUpload'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.createForm.image = this.fileUrl + response.data[0].link
                })
        },
        create() {
            this.createNews(this.createForm)
                .then(() => {
                    this.closeForm()
                })
        },
    },
}
</script>
