<template>
    <div class="section section--products home_popular_product_block nd-popular-card catalog-nd">
        <div class="container-l">
            <p class="main_header">Каталог</p>
            <ul class="list list--flex layout-cards -col-4">
                <li 
                    class="list-item" 
                    v-for="product in products" 
                    :key="product.id" 
                    @click="getProducts(product)" 
                >
                    <a 
                        class="card"
                    >
                        <div class="card-heading">
                            <div class="card-icon">
                                <img 
                                    v-if="product.preview"
                                    class="product-preview-img"
                                    :src="yandexCloud + product.preview" 
                                    alt=""
                                    :style="(!isFirstLevelCatalog ? 'background-color: rgb(199, 199, 197)' : '')"
                                >
                                <svg 
                                    v-if="!product.preview"
                                    class="icon" 
                                    width="60" 
                                    height="60" 
                                    viewBox="0 0 60 60"
                                >
                                    <use xlink:href="@/assets/img/icons.svg#beam_2"></use>
                                </svg>
                            </div>
                            <div class="card-title">{{ product.name }}</div>
                        </div>
                        <div class="card-arrow">
                            <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                                <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                            </svg>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    data() {
        return {
            products: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            main: 'app/main',
            currentWindowWidth: 'app/currentWindowWidth',
            region:'auth/city',
            yandexCloud: 'app/yandexCloud',
        }),
        categoryID1cs() {
          let category_id1cs = ['H2021']
          return category_id1cs
        },
    },
    watch: {
        categoryID1cs() {
            this.getCategories() 
        },
        region() {
            this.getCategories()
        },
    },
    mounted() {
        if (this.categoryID1cs.length != 0) {
            this.getCategories()
        }
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
        }),
        getCategories() {
          this.searchNavbar({parent_ids: this.categoryID1cs, root_id: this.region, componentFrom: 'home/popular'})
              .then((response) => {
                  this.products = response.data
              })
        },  
        getProducts(product) {
            if (product.is_group && !product.is_sub_sign) {
                this.push('catalog/' + product.id_1c)   
            }else{
                this.push('cardProduct/' + product.id)
            }
        },
    },
}
</script>