<template>
    <header class="header">
        <div class="header-container header-container--desktop">
            <div class="header-top">
                <div class="container-l navbar-header-block">
                    <div class="navbar_city_block col-3"> 
                        <div  class="open_region_block">
                            <div @click="isShowRegionsList = !isShowRegionsList" class="open_region_row">
                                <img class="point_icon" src="@/assets/img/navbar_point_icon.png" alt="">
                                <p class="navbar_city_name">{{ choosenCity }}</p>
                                <img v-if="!isShowRegionsList" class="arrow_icon" src="@/assets/img/arrow_down.png" alt="">
                                <img v-if="isShowRegionsList" class="arrow_icon" src="@/assets/img/arrow_up.png" alt="">
                            </div>
                            

                            <div v-if="isShowRegionsList" class="regions_list_block">
                                <p class="regions_header">Выберите город</p>
                                <div class="lists">
                                    <ul class="regions_list">
                                        <li @click="choosenCountry = 'ru'" :class="choosenCountry == 'ru' ? '-active' : ''">
                                            Россия
                                            <img v-if="choosenCountry == 'ru'" src="@/assets/img/red_arrow_right.png" alt="">
                                        </li>
                                        <li @click="choosenCountry = 'kz'" :class="choosenCountry == 'kz' ? '-active' : ''">
                                            Казахстан
                                            <img v-if="choosenCountry == 'kz'" src="@/assets/img/red_arrow_right.png" alt="">
                                        </li>
                                    </ul>
                                    <ul class="cities_list">
                                        <li
                                            :class="choosenCity == cityRegion.name ? '-active' : ''"
                                            v-for="cityRegion, index in regionCityArray"
                                            :key="index"
                                            v-show="(choosenCountry == 'kz' && cityRegion.region == 'H2018') || (choosenCountry == 'ru' && cityRegion.region != 'H2018')"
                                            @click="setChoosenCity(cityRegion)"
                                        >
                                            {{ cityRegion.name }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <button class="button button--primary work_button" @click="push('vacancy')">Работа в МаякМеталл</button>
                    </div>
                    <ul class="list list--flex navigation-header list-nd col-6">
                        <li 
                            class="list-item-nd" 
                            @click="push('aboutcompany')"
                        >
                            <a class="list-link">О компании</a>
                        </li>
                        <li 
                            class="list-item-nd" 
                            @click="push('catalog')"
                        >
                            <a class="list-link">Каталог</a>
                        </li>
                        <li 
                            class="list-item-nd" 
                            @click="push('coatings')"
                        >
                            <a class="list-link">Покрытия</a>
                        </li>
                        <li 
                            class="list-item-nd" 
                            @click="push('documents')"
                        >
                            <a class="list-link">Документы</a>
                        </li>
                        <li 
                            class="list-item-nd"
                        >
                            <a @click="pushToBuild('metall')" class="list-link">Металлоконструкции</a>
                        </li>
                        <li 
                            class="list-item-nd" 
                            @click="push('contacts')"
                        >
                            <a class="list-link">Контакты</a>
                        </li>
                    </ul>
                    <div class="col-3"></div>
                </div>
            </div>
            <div class="header-bottom header-bottom-nd">
                <div class="container-l">
                    <div class="header-bottom-block">
                        <div class="header-logotype-nd col-3" @click="pushWithNavigationClose('')">
                            <a class="logotype">
                                <img src="@/assets/img/logotype.svg" alt="">
                            </a>
                        </div>
                        <div class="header-search-nd col-6" v-click-outside="onClickOutside">
                            <form 
                                class="search search--results" 
                                :class="{'search--focus' : productsNavbar.length != 0 && isShowProductList}" 
                                action="#" 
                                data-search 
                                onsubmit="return false"
                            >
                                <div class="search-field">
                                    <input 
                                        class="search-input" 
                                        v-model="form.query" 
                                        @keyup="getProducts" 
                                        type="search" 
                                        placeholder="Поиск по каталогу" 
                                        data-search-input
                                        @focus="isShowProductList = true"
                                        @keyup.enter="searchProductsPage"
                                    >
                                    <button 
                                        class="search-submit" 
                                        @click="searchProductsPage"
                                    >
                                        <span class="search-submit-label">найти</span>
                                    </button>
                                </div>
                                <div class="search-dropdown">
                                    <div class="search-results"  data-search-results>
                                        <div 
                                            class="search-result" 
                                            v-for="product in productsNavbar" 
                                            :key="product.id"
                                            v-show="product.price != 0"
                                        >
                                            <div class="search-result-image">
                                                <a class="search-result-link" v-if="product.links">
                                                    <img :src="yandexCloud + product.links[0]" alt="">
                                                </a>
                                                <a class="search-result-link" v-else>
                                                    <img src="@/assets/img/default_img_nomenclature.png">
                                                </a>
                                            </div>
                                            <div class="search-result-title" @click="pushToProduct(product)">
                                                <a class="search-result-link">{{ product.name }}</a>
                                            </div>
                                            <div class="search-result-price">
                                                <div class="search-result-price-label">Цена</div>
                                                <div class="search-result-price-value">{{ product.price }} {{ regionCurrency[regionCode].currency }}/м2</div>
                                            </div>
                                            <div class="search-result-button" @click="addInBasket(product)">
                                                <button class="button button--primary button--cart">
                                                    <svg class="icon" width="25" height="25" viewBox="0 0 25 25">
                                                        <use xlink:href="@/assets/img/icons.svg#cart"></use>
                                                    </svg>| 1
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="header-contacts-nd col-3">
                            <div class="right-part"  @mouseleave="closeOfficesList">
                                <div @mouseover="openOfficesList" class="header-phone" style="margin-bottom: 0px;">
                                    <a class="phone" v-if="sourceUtmPhone">{{ maskedSourceUtmPhone }}</a>
                                    <a class="phone" v-if="!sourceUtmPhone && currentAddress">{{ currentAddressPhone }}</a>
                                    <img v-if="!isShowOfficesList" src="@/assets/img/arrow_down.png" alt="">
                                    <img v-if="isShowOfficesList" src="@/assets/img/arrow_up.png" alt="">
                                </div>

                                <svg 
                                    @click="push('basket')" 
                                    class="full_basket_icon nd-icon"  
                                    width="36" 
                                    height="36" 
                                    viewBox="0 0 36 36" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M12.375 30.375C12.9963 30.375 13.5 29.8713 13.5 29.25C13.5 28.6287 12.9963 28.125 12.375 28.125C11.7537 28.125 11.25 28.6287 11.25 29.25C11.25 29.8713 11.7537 30.375 12.375 30.375Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M28.125 30.375C28.7463 30.375 29.25 29.8713 29.25 29.25C29.25 28.6287 28.7463 28.125 28.125 28.125C27.5037 28.125 27 28.6287 27 29.25C27 29.8713 27.5037 30.375 28.125 30.375Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M3.375 5.625H7.875L11.25 24.75H29.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.25 20.25H28.7888C28.9188 20.2501 29.0449 20.2051 29.1456 20.1227C29.2462 20.0402 29.3152 19.9255 29.3407 19.7979L31.3657 9.67289C31.382 9.59125 31.3801 9.50699 31.3599 9.42621C31.3397 9.34543 31.3019 9.27014 31.249 9.20576C31.1962 9.14138 31.1298 9.08953 31.0545 9.05395C30.9792 9.01837 30.897 8.99994 30.8137 9H9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                <svg 
                                    @click="pushToLogin" 
                                    class="lk_icon nd-icon"
                                    width="36" 
                                    height="36" 
                                    viewBox="0 0 36 36"  
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <mask id="path-1-inside-1_1_13330" fill="white">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 13.5C24 15.0913 23.3679 16.6174 22.2426 17.7426C21.1174 18.8679 19.5913 19.5 18 19.5C16.4087 19.5 14.8826 18.8679 13.7574 17.7426C12.6321 16.6174 12 15.0913 12 13.5C12 11.9087 12.6321 10.3826 13.7574 9.25736C14.8826 8.13214 16.4087 7.5 18 7.5C19.5913 7.5 21.1174 8.13214 22.2426 9.25736C23.3679 10.3826 24 11.9087 24 13.5ZM21 13.5C21 14.2956 20.6839 15.0587 20.1213 15.6213C19.5587 16.1839 18.7956 16.5 18 16.5C17.2044 16.5 16.4413 16.1839 15.8787 15.6213C15.3161 15.0587 15 14.2956 15 13.5C15 12.7044 15.3161 11.9413 15.8787 11.3787C16.4413 10.8161 17.2044 10.5 18 10.5C18.7956 10.5 19.5587 10.8161 20.1213 11.3787C20.6839 11.9413 21 12.7044 21 13.5Z"/>
                                    </mask>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 13.5C24 15.0913 23.3679 16.6174 22.2426 17.7426C21.1174 18.8679 19.5913 19.5 18 19.5C16.4087 19.5 14.8826 18.8679 13.7574 17.7426C12.6321 16.6174 12 15.0913 12 13.5C12 11.9087 12.6321 10.3826 13.7574 9.25736C14.8826 8.13214 16.4087 7.5 18 7.5C19.5913 7.5 21.1174 8.13214 22.2426 9.25736C23.3679 10.3826 24 11.9087 24 13.5ZM21 13.5C21 14.2956 20.6839 15.0587 20.1213 15.6213C19.5587 16.1839 18.7956 16.5 18 16.5C17.2044 16.5 16.4413 16.1839 15.8787 15.6213C15.3161 15.0587 15 14.2956 15 13.5C15 12.7044 15.3161 11.9413 15.8787 11.3787C16.4413 10.8161 17.2044 10.5 18 10.5C18.7956 10.5 19.5587 10.8161 20.1213 11.3787C20.6839 11.9413 21 12.7044 21 13.5Z" fill="black" stroke="#FBFBFB" mask="url(#path-1-inside-1_1_13330)"/>
                                    <mask id="path-2-inside-2_1_13330" fill="white">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 1.5C8.8875 1.5 1.5 8.8875 1.5 18C1.5 27.1125 8.8875 34.5 18 34.5C27.1125 34.5 34.5 27.1125 34.5 18C34.5 8.8875 27.1125 1.5 18 1.5ZM4.5 18C4.5 21.135 5.5695 24.021 7.362 26.313C8.62116 24.6602 10.2452 23.3207 12.1075 22.3991C13.9697 21.4775 16.0197 20.9987 18.0975 21C20.1486 20.9976 22.1731 21.4636 24.0167 22.3626C25.8603 23.2615 27.4743 24.5695 28.7355 26.187C30.0352 24.4824 30.9103 22.4928 31.2883 20.3829C31.6664 18.2729 31.5366 16.1033 30.9097 14.0535C30.2828 12.0037 29.1768 10.1326 27.6832 8.59506C26.1896 7.05754 24.3514 5.89779 22.3206 5.21176C20.2898 4.52574 18.1248 4.33316 16.0048 4.64996C13.8848 4.96676 11.8707 5.78384 10.1292 7.03358C8.38762 8.28332 6.96872 9.92981 5.98984 11.8368C5.01097 13.7438 4.50027 15.8565 4.5 18ZM18 31.5C14.9009 31.505 11.8952 30.4388 9.492 28.482C10.4592 27.0969 11.7468 25.9661 13.2451 25.1857C14.7434 24.4053 16.4081 23.9985 18.0975 24C19.7658 23.9985 21.4103 24.3952 22.8945 25.157C24.3787 25.9188 25.6596 27.0237 26.631 28.38C24.2092 30.4005 21.154 31.5049 18 31.5Z"/>
                                    </mask>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 1.5C8.8875 1.5 1.5 8.8875 1.5 18C1.5 27.1125 8.8875 34.5 18 34.5C27.1125 34.5 34.5 27.1125 34.5 18C34.5 8.8875 27.1125 1.5 18 1.5ZM4.5 18C4.5 21.135 5.5695 24.021 7.362 26.313C8.62116 24.6602 10.2452 23.3207 12.1075 22.3991C13.9697 21.4775 16.0197 20.9987 18.0975 21C20.1486 20.9976 22.1731 21.4636 24.0167 22.3626C25.8603 23.2615 27.4743 24.5695 28.7355 26.187C30.0352 24.4824 30.9103 22.4928 31.2883 20.3829C31.6664 18.2729 31.5366 16.1033 30.9097 14.0535C30.2828 12.0037 29.1768 10.1326 27.6832 8.59506C26.1896 7.05754 24.3514 5.89779 22.3206 5.21176C20.2898 4.52574 18.1248 4.33316 16.0048 4.64996C13.8848 4.96676 11.8707 5.78384 10.1292 7.03358C8.38762 8.28332 6.96872 9.92981 5.98984 11.8368C5.01097 13.7438 4.50027 15.8565 4.5 18ZM18 31.5C14.9009 31.505 11.8952 30.4388 9.492 28.482C10.4592 27.0969 11.7468 25.9661 13.2451 25.1857C14.7434 24.4053 16.4081 23.9985 18.0975 24C19.7658 23.9985 21.4103 24.3952 22.8945 25.157C24.3787 25.9188 25.6596 27.0237 26.631 28.38C24.2092 30.4005 21.154 31.5049 18 31.5Z" fill="black" stroke="#FBFBFB" mask="url(#path-2-inside-2_1_13330)"/>
                                </svg>

                                <div v-if="isShowOfficesList" @mouseover="openOfficesList" class="offices_block">
                                    <button @click="openModalCallback()" class="button button--primary call_button">
                                        <img class="phone_icon" src="@/assets/img/phone_icon.png" alt="">
                                        Заказать звонок
                                    </button>
                                    <div class="offices_subblock">
                                        <p class="offices_block_header">Офис продаж и склад</p>
                                        <ul class="navbar_offices_ul">
                                            <li  
                                                v-for="singleAddressBar, idx in officesOfCity"
                                                :key="idx"
                                                class="offices_block_text"
                                            >
                                                {{ singleAddressBar.city }}, {{ singleAddressBar.street }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="offices_subblock">
                                        <p class="offices_block_header">Время работы</p>
                                        <p class="offices_block_text">{{ currentAddress.working_hours }}</p>
                                    </div>
                                    <div class="offices_subblock">
                                        <p class="offices_block_header">Почта</p>
                                        <p v-if="sourceUtmEmail" class="offices_block_text">{{ sourceUtmEmail }}</p>
                                        <p v-else class="offices_block_text">{{ currentAddress.email }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <mobile-nav
            @pushToLogin="pushToLogin" 
        />
    </header>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import vClickOutside from 'v-click-outside'
import mobileNav from './mobile-navigation.vue'

export default {
    mixins: [Functions],
    components: {
        mobileNav,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data () {
        return {
            isShowControls: false,
            isShowChooseTypeUser: false,
            isShowChooseCity: false,
            city: null,
            from: 10,

            options: [
                { id: 1, text: 'Hello' },
                { id: 2, text: 'World' }
            ],

            query: null,
            isShowProductList: false,
            isReady: false,
            defaultValue: '',
            isSetDefaultValue: false,
            valueRegion: null,
            defaultValueRegion: 'Выберите регион',
            isOtherRegion: false,

            organizationId: null,

            isShowAllAdresses: false,

            isShowRegionsList: false,
            isShowOfficesList: false,

            choosenCountry: 'ru',
            choosenCity: '',

            offShowOfficesListId: null, 
            isCheckedLocalRegion: false,
        }
    },
    watch: {
        isReady() {
            this.setDefaultCity()
        },
        topLevelCategory: {
              handler: function () {
                  if (this.isSetDefaultValue == false) {
                      this.isReady = true
                  }
              },
              deep: true
        },
        currentUrl() {
            this.form.query = ''
            this.updateForm(this.form)
        },
        userCompanyId(val) {
            this.organizationId = val
        },
        region() {
            if (this.isCheckedLocalRegion) {
                this.checkChoosenCityLocalStorage()
            }
        },
        cityName() {
            if (this.isCheckedLocalRegion) {
                this.checkChoosenCityLocalStorage()
            }
        },
    },
    mounted() {
        if (this.topLevelCategory.length != 0){
            this.setDefaultCity()
        }
        if (this.userCompanyId) {
            this.organizationId = this.userCompanyId
        }
    },
    created(){
        this.getContacts()
        this.geolocation()
            .then(() => {
                this.checkChoosenCityLocalStorage()
                this.isCheckedLocalRegion = true
            })

        if(localStorage.fromMayak){
            this.from = JSON.parse(localStorage.getItem("fromMayak"))
        }

        this.searchUtm()
            .then(() => {
                setTimeout(() => this.checkSourceUtm(), 300)
            })
    },
    computed:{
        ...Vuex.mapGetters({
            region:'auth/city',
            regions: 'dictionary/regions',
            fileUrl: 'app/fileUrl',
            isAuthenticated:'auth/isAuthenticated',
            isOpenMobileNavigation: 'app/isOpenMobileNavigation',
            isOpenMobileSubNavigation: 'app/isOpenMobileSubNavigation',
            searchForm: 'search/form',
            newPosition: 'basket/newPosition',
            order: 'basket/order',
            productsNavbar: 'search/productsNavbar',
            yandexCloud: 'app/yandexCloud',
            topLevelCategory: 'dictionary/topLevelCategory',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            regionAdresses: 'app/regionAdresses',
            sourceUtmPhone: 'app/sourceUtmPhone',
            sourceUtmEmail: 'app/sourceUtmEmail',
            user: 'auth/user',
            companies: 'companies/companies',
            userCompanyId: 'auth/userCompanyId',
            utmPositions: 'utm/utmPositions',
            currentCard: 'ui_card_address/currentCard',
            ui_contacts: 'ui_contacts/contacts',
            offices: 'ui_contacts/offices',
            cityName: 'auth/cityName',
        }),
        maskedSourceUtmPhone() {
            let masked = ''
            
            let phone = this.sourceUtmPhone
            masked += '+' + phone.substring(0, 1) 
            masked += ' (' + phone.substring(1, 4) + ') ' 
            masked += phone.substring(4, 7) 
            masked += '-' + phone.substring(7)  
            
            return masked
        },
        form() {
            return Object.assign({}, this.searchForm)
        },
        editNewPosition() {
            return Object.assign({}, this.newPosition)
        },
        officesOfCity() {
            let array = []
            this.offices.map((element) => {
                if (element.city == this.choosenCity) {
                    array.push(element)
                }
            })
            return array
        },
        currentAddress() {
            let object = null
            var isSetted = false
            this.offices.map((element) => {
                if (element.city == this.choosenCity && !isSetted) {
                    object = element
                    isSetted = true 
                }
            })
            return object
        },
        currentAddressPhone() {
            var phone = '8 (952) 647-00-63'
            var phones = []
            if (this.currentAddress.phone) {
                phones = this.currentAddress.phone.split(',')
                if (phones[0]) {
                    phone = phones[0]
                }
            }
            return phone
        },
        currentUrl() {
            return this.$route.name
        },
        sourceUtm() {
            return this.$route.query.utm_source
        },
        regionCityArray() {
            var array_result = []
            var array = this.offices
            array.map((element) => {
                if (!this.checkIssetElementInArray(array_result, element.city)) {
                    array_result.push({name: element.city, value: element.city, region: element.region})
                }
            })
            return array_result
        },
    },
    methods:{
        ...Vuex.mapMutations({
            setCity:'auth/setCity',
            setFrom:'auth/setFrom',
            setOpenMobileNavigation: 'app/setOpenMobileNavigation',
            setOpenMobileSubNavigation: 'app/setOpenMobileSubNavigation',
            updateForm: 'search/updateForm',
            clearNewPosition: 'basket/clearNewPosition',
            setProductsNavbar: 'search/setProductsNavbar',
            setRegionCode: 'auth/setRegionCode',
            setOrder: 'basket/setOrder',
            setUserCompanyId: 'auth/setUserCompanyId',
            setSourceUtmPhone: 'app/setSourceUtmPhone',
            setSourceUtmEmail: 'app/setSourceUtmEmail',
            setShowCallbackModalForm: 'app/setShowCallbackModalForm',
            setCityName: 'auth/setCityName',
        }),
        ...Vuex.mapActions({
            searchNavbar: 'search/searchNavbar',
            search: 'catalog/search',
            addPosition: 'basket/addPosition',
            createPosition: 'basket/create',
            searchPage: 'search/searchPage',
            searchUtm: 'utm/search',
            getCardById1c: 'ui_card_address/getCardById1c',
            geolocation: 'ui_contacts/geolocation',
            getContacts: 'ui_contacts/getContacts',
        }),
        openOfficesList() {
            if (this.offShowOfficesListId) {
                clearTimeout(this.offShowOfficesListId)
            }
            this.isShowOfficesList = true
        },
        closeOfficesList() {
            this.offShowOfficesListId = setTimeout(() => {
                this.isShowOfficesList = false
            }, 300)
        },
        openModalCallback() {
            this.setShowCallbackModalForm(true)
            this.isShowOfficesList = false
        },
        checkIssetElementInArray(array, value) {
            var isset = false
            array.map((element) => {
                if (element.name == value) {
                    isset = true
                }
            })
            return isset
        },
        maskedPhone(phone) {
            let masked = ''
            masked += '+' + phone.substring(0, 1) 
            masked += ' (' + phone.substring(1, 4) + ') ' 
            masked += phone.substring(4, 7) 
            masked += '-' + phone.substring(7)  
            
            return masked
        },
        setLocalStorageRegionFirstCity(region) {
            var array = this.offices
            var isSeted = false
            array.map((element) => { 
                if (element.region == region.root_1c_id && !isSeted) {
                    localStorage.setItem('mayakRegionObject', JSON.stringify({city: element.city, region: element.region, utm_phone: region.phone}))
                    this.choosenCity = element.city
                    isSeted = true
                }
            })
        },
        checkSourceUtm(region) {
            let source_utm = this.$route.query.utm_source
            if (source_utm) {
                source_utm = source_utm.replace(/"/g, '')
                this.utmPositions.map((element) => {
                    if (element.utm == source_utm) {
                        if (!region) {
                            // localStorage.setItem('regionMayak', JSON.stringify(element.root_1c_id))
                            this.setLocalStorageRegionFirstCity(element)
                            this.city = element.root_1c_id
                            this.setCity(element.root_1c_id)
                            this.setSourceUtmPhone(element.phone)
                            this.setSourceUtmEmail(element.email)

                        }else{
                            if (region == element.root_1c_id) {
                                this.setSourceUtmPhone(element.phone)
                                this.setSourceUtmEmail(element.email)
                            }else{
                                // this.setSourceUtmPhone(null)
                                this.setSourceUtmEmail(null)
                            }
                        }
                    }
                })
            }else{
                // this.setSourceUtmPhone(null)
                this.setSourceUtmEmail(null)
            }
        },
        changeCompanyId() {
            localStorage.setItem('mayak_company_id', JSON.stringify(this.organizationId)) 
            this.setUserCompanyId(this.organizationId)
        },
        changeFrom(val){
            this.from = val
            this.setFrom(val)
            localStorage.setItem('fromMayak', JSON.stringify(val))
        },
        pushToLogin() {
            if (this.isAuthenticated) {
                this.push('nlk')
            }else{
                this.push('login')
            }
            this.setOpenMobileNavigation(false)
            this.setOpenMobileSubNavigation(false)
        },
        pushWithNavigationClose(str) {
            this.push(str)
            this.setOpenMobileNavigation(false)
            this.setOpenMobileSubNavigation(false)
        },
        getProducts() {
            if (!this.city) {
                this.form.query = ''
                this.updateForm(this.form)
                return
            }
            if (this.form.query.length < 4) {
                this.setProductsNavbar([])
                return
            }
            this.form.root_id = this.region
            this.updateForm(this.form)
            this.searchNavbar()
        },
        pushToProduct(product) {
            if (product.is_group && !product.is_sub_sign) {
                this.form.parent_id = product.id_1c
                this.updateForm(this.form)
                this.search()
                this.form.query = null
                this.isShowProductList = false
                this.push('catalog/' + product.id_1c)
            }else{
                this.form.query = null
                this.isShowProductList = false
                this.push('cardProduct/' + product.id)
            }
        },
        addInBasket(product) {
            if (product.is_group) {
                this.form.parent_id = product.id_1c
                this.updateForm(this.form)
                this.search()
                this.query = null
                this.push('/catalog/' + product.id_1c)
            }else{
                this.push('cardProduct/' + product.id)
                this.isShowProductList = false
            }           
        },
        searchProductsPage() {
            if (!this.form.query || this.form.query.length < 3) {
                return
            }
            this.form.root_id = this.region
            this.updateForm(this.form)
            this.searchPage()
            this.isShowProductList = false
            this.push('search')
        },
        onClickOutside() {
            this.isShowProductList = false
        },
        setDefaultCity() {
            let name = ''
            this.topLevelCategory.map((element) => {
                if (element.id_1c == this.city) {
                    name = element.name
                }
            })

            if (!name) {
                name = 'Выберите регион'
            }

            this.defaultValue = name
            this.isSetDefaultValue = true
        },
        pushToCalculators() {
            this.push('')

            setTimeout(() => {
                let element = document.getElementById('main_page_calcurators')
                let top = element.offsetTop
                window.scrollTo(0, top)
            }, 500)
        },
        pushToBuild(str) {
            if (str == 'build') {
                window.open('https://mk.mayakmetall.ru/', '_blank')
            }else if (str == 'metall') {
                window.open('https://mk.mayakmetall.ru/mk', '_blank')
            }
        },
        setChoosenCity(element) {
            localStorage.setItem('mayakRegionObject', JSON.stringify({city: element.name, region: element.region, utm_phone: ''}))
            this.choosenCity = element.name
            this.setCityName(element.name)
            this.setCity(element.region)
            this.isShowRegionsList = false
            if (localStorage.mayakOrderId) {
                localStorage.removeItem("mayakOrderId")
                this.setOrder(
                    {
                        id: null,
                        positions: [],
                        
                        account_type: null,
                        location: null,
                        postcode: null,

                        delivery_type: null,
                        pay_type: null,

                        organization_name: null,
                        organization_address: null,
                        inn: null,
                        kpp: null,

                        fio: null,
                        email:null,
                        phone: null,
                        comment: null,
                    }
                )
            }
        },
        checkChoosenCityLocalStorage() {
            var regionObj = JSON.parse(localStorage.getItem('mayakRegionObject'))
            if (regionObj) {
                if (regionObj.region == 'H2018') {
                    this.choosenCountry = 'kz'
                }
                this.choosenCity = regionObj.city
                this.city = regionObj.region
                this.setCity(this.city)
                this.setSourceUtmPhone(regionObj.utm_phone)
            }else{
                if (this.offices.length != 0) {
                    var firstOffice = this.offices[0]
                    localStorage.setItem('mayakRegionObject', JSON.stringify({city: firstOffice.city, region: firstOffice.region, utm_phone: ''}))
                    this.choosenCity = firstOffice.city
                    if (this.offices[0].region == 'H2018') {
                        this.choosenCountry = 'kz'
                    }
                    this.city = firstOffice.region
                    this.setCity(this.city)
                }
            }
        },
    }
}
</script>
<style scoped src="../../css/castom.css"></style>
<style lang="scss">
    .header-address{
        position: relative;   
    }
    .addresses-list-block{
        position: absolute;
        top: 20px;
        right: 0;
        background-color: white;
        padding: 20px;
        border: 1px solid #e7e7e7;
        border-radius: 10px;

        .address-item{
            margin-bottom: 10px;
        }
    }
    .navbar_link_email{
        text-decoration: unset;
        &:hover{
            color: #ED1C24;
        }
    }

    .right-part{
        display: flex;
        flex-wrap: nowrap;
        position: relative;
    }

    .navbar_city_block{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-top: 28px;
        margin-right: 18px;
        position: relative;
        justify-content: flex-end;

        .open_region_block{
            position: relative;

            .open_region_row{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        .point_icon{
            height: 24px;
            width: 24px;
            margin-right: 4px;
        }

        .arrow_icon{
            height: 16px;
            width: 16px;
        }

        .navbar_city_name{
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            margin: 0;
            margin-right: 10px;
        }

        .work_button{
            border-radius: 10px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            padding: 4px 20px;
            margin-left: 18px;
        }
    }

    .header-bottom-block{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;

        .header-logotype-nd{
            margin-right: 18px;

            .logotype{
                height: 27px;
                width: 240px;
                float: right;

                img{
                    width: 184px;
                    height: 26px;
                    max-width: 100% !important;
                }
            }
        }

        .header-search-nd{
            margin-right: 20px;
            .search{
                max-width: 100%;
            }
        }

        .header-contacts-nd{
            .right-part{
                align-items: center;

                .header-phone{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    cursor: pointer;

                    img{
                        padding-left: 10px;
                        padding-right: 11px;
                    }
                }
            }

            .full_basket_icon{
                height: 35px;
                margin-right: 20px;
                cursor: pointer;
            }

            .lk_icon{
                height: 35px;
                cursor: pointer;
            }
        }
    }

    .regions_list_block{
        position: absolute;
        top: 40px;
        left: 0;
        background-color: white;
        box-shadow: 0px 4px 20px 0px #0000001A;
        padding-top: 25px;
        padding-left: 25px;
        padding-right: 40px;
        padding-bottom: 50px;
        border-radius: 10px;

        .lists{
            display: flex;
        }

        .regions_header{
            font-weight: 700;
            font-size: 2.2rem;
            line-height: 2.4rem;

            margin-bottom: 39px;
        }

        .regions_list{
            margin-left: 0;
            margin-right: 60px;
            list-style-type: none;

            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.4rem;

            li{
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                
                img{
                    margin-left: 10px;
                }

                &:hover{
                    color: #ED1C24;
                    cursor: pointer;
                }
            }
            .-active{
                color: #ED1C24;
                font-weight: 600;
            }
        }

        .cities_list{
            margin-left: 0;
            list-style-type: none;

            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.4rem;

            li{
                margin-bottom: 10px;

                &:hover{
                    color: #ED1C24;
                    cursor: pointer;
                }
            }

            .-active{
                font-weight: 600;
                color: #ED1C24;
            }
        }
    }

    .offices_block{
        position: absolute;
        top: 43px;
        right: 110px;
        background-color: white;
        box-shadow: 0px 4px 20px 0px #0000001A;
        border-radius: 10px;
        width: 100%;
        min-width: 400px;
        padding-left: 25px;
        padding-top: 22px;
        padding-right: 34px;
        padding-bottom: 21px;
        // display: flex;
        // flex-direction: column;
        // justify-content: start;

        .phone_icon{
            margin-right: 10px;
        }

        .call_button{
            border-radius: 10px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            padding: 8px 10px;
            margin-bottom: 22px;
        }

        .offices_subblock{
            margin-bottom: 20px;
        }

        .offices_block_header{
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin-bottom: 9px;
        }

        .offices_block_text{
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin-bottom: 9px;
            color: grey;
        }
    }

    .navbar_offices_ul{
        li{
            // margin-left: -20px;

            &::marker{
                color: #ED1C24;
            }
        }
    }
</style>