import Http from "../../utils/http"
import Config from "../../config"

const state = {
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    news: [],
    newsFront: [],
    currentNews: {},
}

const getters = {
    form: state => state.form,
    news: state => state.news,
    newsFront: state => state.newsFront,
    currentNews: state => state.currentNews,
}

const actions = {
    getNews: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_news/search", payload)
                .then(response => {
                    commit("setNews", response.data)
                    commit("setNewsFront", response.data)
                    resolve(response)
                })
        })
    },
    getOne: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_news/search", {ids: [Number(id)]})
                .then(response => {
                    commit("setCurrentNews", response.data)
                    resolve(response)
                })
        })
    },
    createNews: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_news/admin", payload)
                .then(response => {
                    commit("pushNews", response.data)
                    resolve(response)
                })
        })
    },
    deleteNews: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_news/admin/" + id)
                .then(response => {
                    commit("spliceNews", id)
                    resolve(response)
                })
        })
    },
    updateNews: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_news/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeNews", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setCurrentNews: (state, val) => {
        if (Array.isArray(val) && val.length != 0) {
            state.currentNews = val[0]
        }
    },
    setNews: (state, val) => {
        val.sort((a, b) => Number(a.id) - Number(b.id))
        state.news = val
    },
    setNewsFront: (state, val) => {
        var array = [...val]
        array.sort((a, b) => Number(b.id) - Number(a.id))
        state.newsFront = array
    },
    spliceNews: (state, id) => {
        state.news.map((element, idx) => {
            if (id == element.id) {
                state.news.splice(idx, 1)
            }
        })
    },
    changeNews: (state, val) => {
        state.news.map((element) => {
            if (element.id == val.id) {
                element.data = val.data
                element.description = val.description
                element.image = val.image
                element.name = val.name
                element.root_id = val.root_id
            }
        })
    },
    pushNews: (state, val) => {
        state.news.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
