<template>
    <section class="about_company-info_page-sp_cards">
        <div class="container-l">
            <p class="main_header">Сэндвич-панели специального назначения</p>
            <div class="cards-block">
                <div @click="pushToCard('evosteel')" class="card-sp"> 
                    <img class="arrow" src="@/img/info-sp/Strelka.png" alt="arrow"> 
                    <img class="main_img" src="@/img/info-sp/card1.png" alt="sp card 1">
                    <div class="text_block">
                        <p class="main_header">Для чистых помещений</p>
                        <p class="subheader">
                            Покрытие облицовки эффективно защищает панели от коррозии в условиях высокой 
                            концентрации химических веществ
                        </p>
                    </div>
                </div>
                <div @click="pushToCard('eurolight')" class="card-sp">  
                    <img class="arrow" src="@/img/info-sp/Strelka.png" alt="arrow"> 
                    <img class="main_img" src="@/img/info-sp/card2.png" alt="sp card 2">
                    <div class="text_block">
                        <p class="main_header">Для пожароопасных помещений</p>
                        <p class="subheader">
                            Для пожароопасных и взрывоопасных производств
                        </p>
                    </div>
                </div>
                <div @click="pushToCard('cordplus')" class="card-sp">  
                    <img class="arrow" src="@/img/info-sp/Strelka.png" alt="arrow"> 
                    <img class="main_img" src="@/img/info-sp/card3.png" alt="sp card 3">
                    <div class="text_block">
                        <p class="main_header">С кабель-каналами</p>
                        <p class="subheader">
                            Разработаны специально для использования в строительстве модульных домов и зданий требующих 
                            скрытой проводки
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'

export default {
    computed: {
        ...Vuex.mapGetters({
            spCardsMap: 'dictionary/spCardsMap',
        }),
    },
    methods: {
        getLinkOfMap(name) {
            let link = null
            this.spCardsMap.map((element) => {
                if (element.name == name) {
                    if (element.id_1c) {
                        link = 'catalog/' + element.id_1c
                    }else{
                        link = element.link
                    }
                }
            })
            return link
        },
        pushToCard(name) {
            if (!this.getLinkOfMap(name)) {
                return
            }
            window.open('/' + this.getLinkOfMap(name), '_blank')
        },
    },
}
</script>