<template>
    <div class="nd-about-company_page">
        <div class="container-l">
            <bread-crumbs class="breadcrumbs" :pages-array="pages" :withNdProp="false" />
        </div>
        <div class="container-l" style="margin-top: 89px;">
            <div class="news_block">
                <div class="news_list">
                    <div
                        v-for="singleNews in newsFront.slice((currentPage - 1) * limitPage, currentPage * limitPage)"
                        :key="singleNews.id" 
                        class="single_news"
                        @click="pushToNews(singleNews)" 
                    >
                        <img :src="singleNews.image" alt="">
                        <p class="single_news_data">{{ formatDate(singleNews.data) }}</p>
                        <p class="single_news_name">{{ singleNews.name }}</p>
                    </div>
                </div>
                <v-pagination
                    v-if="amountPages > 1"
                    class="custom_pagination"
                    v-model="currentPage"
                    :page-count="amountPages"
                ></v-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Functions from '@/utils/functions'
import Vuex from 'vuex'
import vPagination from 'vue-plain-pagination'

export default {
    mixins: [Functions],
    components: {
        breadCrumbs,
        vPagination,
    },
    data() {
        return {
            pages: [
                {id: 0, name: "О компании", url: "aboutcompany", fullUrl: 'aboutcompany'},
                {id: 10, name: "Новости", url: "news", fullUrl: 'aboutcompany/news'},
            ],
            currentPage: 1,
            amountPages: 1,
            limitPage: 9,
            bootstrapPaginationClasses: {
                ul: 'list list--flex pagination',
                li: 'list-item',
                liActive: '-active',
                liDisable: 'disabled',
                button: 'list-link'
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            news: 'ui_news/news',
            newsFront: 'ui_news/newsFront',
        }),
    },
    mounted() {
        this.getNews({})
            .then(() => {
                this.setPagination()
            })
    },
    methods: {
        ...Vuex.mapActions({
            getNews: 'ui_news/getNews',
        }),
        formatDate(date) {
            var splited_date = ''
            var result_date = ''
            if (typeof date == 'string' && date.includes('-')) {
                splited_date = date.split('-')
                result_date = splited_date[2] + '.' + splited_date[1] + '.' + splited_date[0]
            }else{
                result_date = date
            }
            return result_date
        },
        setPagination() {
            var rows = 0
            if (this.news.length > 0) {
                rows = this.news.length
            }
            if (rows > 0) {
                this.amountPages = Math.ceil(rows / this.limitPage)
            }
        },
        pushToNews(news) {
            if (news.link) {
                this.push(news.link)
            }else{
                this.push('aboutcompany/single/'+ news.id)
            }
        },
    },
}
</script>
<style lang="scss">
    .about_company_nd_news{
        .news_block{
            margin-bottom: 200px;

            .header_block{
                margin-bottom: 120px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;

                .news_header{
                    font-weight: 600;
                    font-size: 3.2rem;
                    line-height: 3.8rem;
                    margin: 0;
                }

                .news_header_button{
                    border: 1px solid black;
                    background-color: white;
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                    padding: 10px 20px;
                }
            }

            .news_list{
                display: flex;
                flex-wrap: nowrap;
                gap: 20px;

                .single_news{
                    width: 33%;

                    .single_news_data{
                        font-weight: 700;
                        font-size: 1.8rem;
                        line-height: 2.3rem;
                        color: #808080;
                        padding: 0 10px;
                        margin-bottom: 20px;
                    }

                    .single_news_name{
                        font-weight: 600;
                        font-size: 2.3rem;
                        line-height: 2.8rem;
                        padding: 0 10px;
                    }

                    img{
                        width: 100%;
                    }

                    &:hover{
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .custom_pagination{
        list-style-type: none;
        display: flex;
        gap: 16px;
        justify-content: flex-end !important;

        .pagination-item{
            button{
                background-color: #fff;
                border: unset;
                font-weight: 400;
                font-size: 22px;
            }
        }

        .pagination-item--active{
            button{
                background-color: #ED1C24;
                border-radius: 10px;
                color: #fff;
                padding: 2px 10px;
            }
        }
    }
</style>