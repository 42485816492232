<template>
    <section class="nd-vacancy_main_page">
        <div class="container">
            <div 
                v-if="currentMainPage.main_block"
                class="main_block"
            >
                <p class="vacancy_header">{{ currentMainPage.main_block.header }}</p>
                <p class="vacancy_subheader">{{ currentMainPage.main_block.subheader }}</p>

                <button class="button button--primary" @click="push('vacancies')">Наши вакансии</button>

                <div class="photo_block">
                    <img 
                        v-for="singleImg, idx in currentMainPage.main_block.list_imgs"
                        :key="idx"
                        :class="'image' + (idx + 1)" 
                        :src="singleImg" 
                        alt=""
                    >
                </div>
            </div>
        </div>
        <div class="container">
            <div 
                v-if="currentMainPage.progress_block"
                class="progress_block"
            >
                <p class="progress_header">{{ currentMainPage.progress_block.header }}</p>
                <p class="progress_description">{{ currentMainPage.progress_block.description }}</p>
                <div class="progress_list">
                    <div 
                        v-for="singleProgress, idx in currentMainPage.progress_block.list_progress"
                        :key="idx"
                        class="progress_card"
                    >
                        <img :src="singleProgress.img" alt="">
                        <p v-if="idx == 0 || idx == 3" class="progress_card_header">{{ singleProgress.name }}</p>
                        <div v-if="idx == 1 || idx == 2" style="margin-bottom: 12px;">
                            <p v-for="spName in singleProgress.name.split(' ')" style="margin: 0;" :key="spName" class="progress_card_header">
                                {{ spName }}
                            </p>
                        </div>
                        <p class="progress_card_description">{{ singleProgress.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="currentMainPage.video_link" class="container">
            <div class="video_link" style="background-color: black;">
                <div
                    v-show="isVideoStart"
                    class="modal-iframe video"
                    :style="!isVideoStart ? 'opacity: 0.5' : ''"
                >
                    <iframe 
                        :src="currentMainPage.video_link"
                        width="853" 
                        height="480" 
                        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" 
                        frameborder="0" 
                        allowfullscreen>
                    </iframe>
                </div>
                <img v-if="!isVideoStart" class="video" src="@/assets/img/vacancy/video_cover.png" alt="">
                <img v-if="!isVideoStart" @click="isVideoStart = true" class="video_icon" src="@/assets/img/vacancy/video_play_icon.png" alt="">
                <p v-if="!isVideoStart" class="video_link_name">Видео о компании МаякМеталл</p>
            </div>
        </div>
        <div class="container">
            <div class="advantages_block">
                <p class="advantages_block_header">Преимущества</p>

                <div class="advantages">
                    <div 
                        v-for="single_advantage, idx in currentMainPage.advantages_list"
                        :key="idx"
                        class="single_advantage"
                    >
                        <img :src="single_advantage.img" alt="">
                        <p class="single_advantage_name">{{ single_advantage.name }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div v-if="currentMainPage.general_block" class="general_block">
                <div style="width: 57em; margin-bottom: 30px;">
                    <p class="general_block_header">{{ currentMainPage.general_block.header }}</p>
                </div>
                <div style="width: 57em;">
                    <p class="general_block_description">{{ currentMainPage.general_block.description }}</p>
                </div>
                <div class="block_with_image">
                    <div class="left_part">
                        <p class="general_quote">{{ currentMainPage.general_block.quote }}</p>
                        <p class="general_name">Шульпин Михаил</p>
                    </div>
                    <div class="right_part">
                        <img class="general_image" :src="currentMainPage.general_block.img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div v-if="currentMainPage.banner_block" class="banner_block">
                <p class="banner_header">{{ currentMainPage.banner_block.header }}</p>
                <img class="banner_image" :src="currentMainPage.banner_block.img" alt="">
            </div>
        </div>
        <div class="container">
            <div v-if="currentMainPage.questions_list" class="questions_block">
                <p class="question_block_header">У вас есть вопросы?</p>
                <div class="questions_list">
                    <div 
                        v-for="single_question, idx in currentMainPage.questions_list"
                        :key="idx"
                        class="question_row"
                    >
                        <div class="text_block">
                            <p class="question_name">{{ single_question.question }}</p>
                            <p v-if="numShowAnswer == idx" class="question_answer">{{ single_question.answer }}</p>
                        </div>
                        <i @click="numShowAnswer = idx" v-if="numShowAnswer != idx" class="fas fa-angle-down"></i>
                        <i @click="numShowAnswer = null" v-if="numShowAnswer == idx" class="fas fa-angle-up"></i>
                    </div>
                </div>

                <button class="button button--primary" @click="scrollToBlock">Задать свой вопрос</button>
            </div>
        </div>
        <div class="container">
            <div v-if="currentMainPage.interview_list" class="interview_block">
                <div class="interview_header_block">
                    <p class="interview_header">Интервью с сотрудниками</p>
                    <div class="block_arrows">
                        <!-- <img @click="changeInterview('minus')" src="@/assets/img/arrow_left.svg" alt=""> -->
                        <svg @click="changeInterview('minus')" width="50" height="30" viewBox="0 0 66 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.585785 13.5858C-0.195259 14.3668 -0.195259 15.6332 0.585785 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857864C15.3611 0.0768156 14.0948 0.0768156 13.3137 0.857864L0.585785 13.5858ZM66 13L2 13V17L66 17V13Z" fill="black"/>
                        </svg>
                        <svg @click="changeInterview('plus')" width="50" height="30" viewBox="0 0 66 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M65.4142 16.4142C66.1953 15.6332 66.1953 14.3668 65.4142 13.5858L52.6863 0.857864C51.9052 0.0768156 50.6389 0.0768156 49.8579 0.857864C49.0768 1.63891 49.0768 2.90524 49.8579 3.68629L61.1716 15L49.8579 26.3137C49.0768 27.0948 49.0768 28.3611 49.8579 29.1421C50.6389 29.9232 51.9052 29.9232 52.6863 29.1421L65.4142 16.4142ZM0 17H64V13H0V17Z" fill="black"/>
                        </svg>

                        <!-- <img @click="changeInterview('plus')" src="@/assets/img/vacancy/arrow_right.png" alt=""> -->
                    </div>
                </div>
                <div 
                    v-for="single_interview, idx in currentMainPage.interview_list"
                    :key="idx"
                    class="interview_main_block"
                    v-show="numShowInterview == idx"
                >
                    <div class="left_part">
                        <div class="custom_interview_avatar_block">
                            <img class="interviewer_avatar" :src="single_interview.img" alt="">
                        </div>
                        <p class="interviewer_name">{{ single_interview.name }}</p>
                        <p class="interviewer_description">{{ single_interview.post }}</p>
                    </div>
                    <div class="right_part">
                        <!-- <p class="interview_text">{{ single_interview.text }}</p> -->
                        <p class="interview_text" v-html="single_interview.text"></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" id="question_form_block">
            <div class="question_form_block">
                <div class="left_part">
                    <p class="question_form_block_header">Задать свой<br> вопрос</p>
                </div>
                <div class="right_part">
                    <input type="text" placeholder="Email" v-model="newQuestion.email">
                    <textarea v-model="newQuestion.question" placeholder="Напишите свой вопрос" name="" id="" rows="4"></textarea>
                    <button @click="sendQ" class="button button--default">Задать вопрос</button>
                </div>
            </div>
        </div>

        <div class="container">
            <resume-form />
        </div>
    </section>
</template>
<script>
import resumeForm from './resume_form.vue'
import Functions from '@/utils/functions'
import Vuex from 'vuex'

export default {
    mixins: [Functions],
    components: {
        resumeForm,
    },
    data() {
        return {
            numShowAnswer: null,
            numShowInterview: 0,
            newQuestion: {
                question: null,
                email: null,
            },
            isVideoStart: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            vacanciesMainPage: 'vacancy/vacanciesMainPage',
            user: 'auth/user',
        }),
        currentMainPage() {
            var element = {}
            if (this.vacanciesMainPage[0]) {
                element = this.vacanciesMainPage[0]
            }
            return element
        }
    },
    mounted() {
        this.getVacanciesMainPage()
        this.checkAuth()
    },
    methods: {
        ...Vuex.mapActions({
            getVacanciesMainPage: 'vacancy/search',
            sendQuestion: 'vacancy/sendQuestion',
        }),
        openLink(link) {
            window.open(link, '_blank')
        },
        changeInterview(type) {
            if (type == 'plus') {
                if (this.currentMainPage.interview_list.length - 1 == this.numShowInterview) {
                    return
                }   
                this.numShowInterview += 1
            }else if (type == 'minus') {
                if (this.numShowInterview == 0) {
                    return
                }
                this.numShowInterview -= 1
            }
        },
        sendQ() {
            if (!this.newQuestion.question) {
                return
            }

            var payload = {
                question: this.newQuestion.question,
                email: this.newQuestion.email,
            }

            this.sendQuestion(payload)
                .then(() => {
                    this.newQuestion.question = null
                    this.newQuestion.email = null
                })
        },
        checkAuth() {
            if (this.user.id) {
                this.newQuestion.email = this.user.email
            }
        },
        scrollToBlock() {
            let topElement = document.querySelector('#question_form_block')
            window.scrollTo({top: topElement.offsetTop, behavior: 'smooth'})
        },
    },
}
</script>
<style lang="scss">
    .nd-vacancy_main_page{
        .question_form_block{
            background-color: #353B48;
            padding: 60px;
            margin-bottom: 150px;

            display: flex;
            flex-wrap: nowrap;

            .question_form_block_header{
                font-size: 5.6rem;
                line-height: 7.2rem;
                color: white;
                margin-bottom: 27px;
                font-weight: 700;
            }

            .left_part{
                width: 50%;
            }

            .right_part{
                width: 50%;
            }

            input{
                width: 100%;
                margin-bottom: 26px;
                border-radius: 10px;
                background-color: #353B48;
                color: white;
                padding: 10px 20px;
                border: 1px solid white;
            }

            textarea{
                width: 100%;
                margin-bottom: 26px;
                border-radius: 10px;
                background-color: #353B48;
                color: white;
                padding: 20px;
                border: 1px solid white;
            }

            .button{
                width: 100%;
                border-radius: 10px;
                padding: 10px 40px;

                font-size: 2.3rem;
                line-height: 2.8rem;
            }
        }
    }   

    .custom_interview_avatar_block{
        height: 25.1em;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 60px;
    }

    // .custom_interview_avatar_block_admin{
    //     height: 25.1em;
    //     overflow: hidden;
    //     border-radius: 50%;
    //     margin-bottom: 60px;

    //     img{
    //         width: 100%;
    //     }
    // }

    @media (max-width: 1699px) {
        .custom_interview_avatar_block{
            height: 19.1em;
        }

        // .custom_interview_avatar_block_admin{
        //     height: 15.1em;
        // }
    }

    
</style>