<template>
    <section class="about_company-info_page-sp_description">
        <div class="container-l"> 
            <p class="main_header">Сэндвич-панели производства МаякМеталл</p> 
            <div class="description_block">
                <div class="left_part">
                    <img class="description_img" src="@/img/info-sp/description.png" alt="">
                </div>
                <div class="right_part">
                    <p class="description_text">
                        Сэндвич-панели нашего производства представляют собой трехслойную конструкцию, 
                        состоящую из двух листов металла, между которыми расположена теплоизоляционная 
                        прослойка. Металл крепится к утеплителю с помощью двухкомпонентного клея.
                    </p>
                    <div class="description_once">
                        <img src="@/img/nd-imgs/calc_1.png" alt="">
                        <p>Стальной оцинкованный лист высшей категории</p>
                    </div>
                    <div class="description_once">
                        <img src="@/img/nd-imgs/calc_2.png" alt="">
                        <p>Ламели минваты повышенной плотности</p>
                    </div>
                    <div class="description_once">
                        <img src="@/img/nd-imgs/calc_3.png" alt="">
                        <p>Двухкомпонентный клей, обеспечивающий особо высокую плотность соединения</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {

}
</script>